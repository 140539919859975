import React from "react";
import env from '../../env.json';
import {Image} from "react-bootstrap";

const round = ""
const princess = ""
const pear = ""
const oval = ""
const heart = ""
const marquise = ""
const emeraid = ""
const cushion = ""
const asscher = ""
const radiant = ""

const CaratImage = ({shape}: any) => {

    let urlName = "";

    switch (shape) {
        case "Round":
            urlName = round;
            break;
        case "Princess":
            urlName = princess
            break;
        case "Cushion Modified":
            urlName = cushion
            break;
        case "Oval":
            urlName = oval
            break;
        case "Emerald":
            urlName = emeraid
            break;
        case "Pear":
            urlName = pear
            break;
        case "Asscher":
            urlName = asscher
            break;
        case "Heart":
            urlName = heart
            break;
        case "Radiant":
            urlName = radiant
            break;
        case "Marquise":
            urlName = marquise
            break;
        default:
    }

    return (
        <Image src={env.wp_media_path + urlName}/>
    );
}

export default CaratImage;