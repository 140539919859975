import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import { ApiProvider } from './ApiContext';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";

import DiamondListing from './pages/diamond-finder';
import EarringListing from './pages/earrings-finder';
import DiamondShow from './pages/diamond-show'
import EarringShow from './pages/earrings-show'
import CustomDesignRing from './pages/custom-design-ring'
import LooseDiamondFinder from './pages/loose-diamond-finder'
import {Provider} from "react-redux";
import {store} from "./redux/store";
// import ErrorPage from './pages/404'

const router = createBrowserRouter([
    {
        path: "/create-your-engagement-ring/:productID",
        element: <DiamondListing/>,
    },
    {
        path: "/create-your-pendant/:productID",
        element: <DiamondListing/>,
    },
    {
        path: "/search-loose-diamond/:productID",
        element: <LooseDiamondFinder/>,
    },
    {
        path: "/create-your-earrings/:productID",
        element: <EarringListing/>,
    },
    {
        path: "/earring-showcase/:productID",
        element: <EarringListing/>,
    },
    {
        path: "/diamond-show/:diamondID/:productID",
        element: <DiamondShow/>,
    },
    {
        path: "/earring-show/:firstDiamondID/:secondDiamondID/:productID",
        element: <EarringShow/>,
    },
    {
        path: "/customized-design-ring",
        element: <CustomDesignRing/>,
    },
]);


const root = ReactDOM.createRoot(document.getElementById('sarvada-react-root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
