import axios from "axios";


async function APIPostCall(url: string, header: any, data: any) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: url,
            headers: header,
            data: data,
        };
        axios(options).then((res) => {
            resolve(res.data);
        })
            .catch(error => {
                // reject(re)
            });
    })
}


export async function callRepnetAPI(url: string, token: string, body: any) {
    delete body.fluorescence_intensities
    if (body.clarity_to == 0) {
        body.clarity_to = 1

    }

    const header = {
        'Authorization': token
    }

    const actualBody = {
        request: {
            body: body,
        }
    }

    const res: any = await APIPostCall(url, header, actualBody)

    const repRes = res.response;

    if (repRes.header.error_message !== "" && repRes.header.error_message === "No Diamond was found") {
        return null;
    } else {
        return repRes.body

    }


}

export function callWordpressAPI(url: string) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        axios(options).then((res) => {
            resolve(res.data)
        })
            .catch(error => {
            });
    })

}
