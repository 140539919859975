import React from "react";
import {Image} from 'react-bootstrap';

const GIA = ""
const IGI = ""
const HRD = ""

type LabComponentDto = {
    lab: string,
    cert_file?: string
}
const LabComponent = ({lab, cert_file}: LabComponentDto) => {

    if (!cert_file) {
        switch (lab) {
            case "IGI":
                return <Image src={IGI} fluid/>;
            case "GIA":
                return <Image src={GIA} fluid/>;
            case "HRD":
                return <Image src={HRD} fluid/>;
            default:
                return null;
        }
    } else {
        return <Image src={cert_file} fluid/>;
    }


}

export default LabComponent;