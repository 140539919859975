import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import {Row} from "react-bootstrap";
import env from "../../../env.json";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from 'axios';
import {diamondReducerAction} from '../../../redux/reducers/diamondReducer'
import {RootState} from "../../../redux/store";

export default function Whishlist() {
    const {productID} = useParams();
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [diamondDetails, setDiamondDetails] = useState<any>([])

    function recursiveData(index: number, diamonds: any, diamondDetails: any, cb: Function) {
        const options = {
            method: 'POST',
            url: env.newRepNetSingleURL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ""
            },
            data: JSON.stringify({
                request: {
                    header: {
                        // username: env.username,
                        // password: env.password,
                    },
                    body: {
                        diamond_id: parseInt(diamonds[index])
                    },
                }
            }),
        };
        axios(options).then((res) => {

            if (res.data.response.header.error_message !== "" && res.data.response.header.error_message === "No Diamond was found") {
            } else {
                diamondDetails.push(res.data.response.body.diamond)
            }
            index = index + 1
            if (index < diamonds.length) {
                recursiveData(index, diamonds, diamondDetails, cb);
            } else {
                cb(diamondDetails)
            }
        })
            .catch(error => {
                index = index + 1
                recursiveData(index, diamonds, diamondDetails, cb);
            });
    }

    useEffect(() => {
        recursiveData(0, state.wishlist.diamonds, [], (d: any) => {
            setDiamondDetails([...d])
        })
    }, [])
    const diamondsData = diamondDetails ? diamondDetails.map((diamond: any, index: number) => (
        <tr key={index}>
            <td>
                <input
                    type="checkbox"
                    name="compare"
                    checked={state.diamondReducer.comparisonDiamond.filter(item => item.diamond_id === diamond.diamond_id).length > 0 ? true : false}
                    onClick={(e: any) => e.target.checked === true ? dispatch(diamondReducerAction.ADD_IN_COMPARISON((diamond))) : dispatch(diamondReducerAction.REMOVE_IN_COMPARISON((diamond)))}
                    disabled={state.diamondReducer.comparisonDiamond.length > 4 && state.diamondReducer.comparisonDiamond.filter(item => item.diamond_id === diamond.diamond_id).length === 0}
                />
            </td>
            <td> {diamond.shape} </td>
            <td> {diamond.size} </td>
            <td> {diamond.color} </td>
            <td> {diamond.clarity} </td>
            <td> {diamond.cut} </td>
            <td> {diamond.polish} </td>
            <td> {diamond.symmetry} </td>
            <td> {diamond.fluor_intensity} </td>
            <td> {diamond.lab} </td>
            <td> {diamond.total_sales_price_in_currency} </td>
            <td> {diamond.table_percent} </td>
            <td> {diamond.depth_percent} </td>
            <td> {diamond.meas_length}x{diamond.meas_width}x{diamond.meas_depth} </td>
        </tr>
    )) : <p>No Whishlist Data Found</p>;

    return (
        <>
            <Row className="ListView">
                <Table
                    responsive
                    striped
                    bordered
                    hover
                    className="ext-table text-center"
                >
                    <thead>
                    <tr>
                        <th> Add To <br/> Compare</th>
                        <th> Shape</th>
                        <th> Carat</th>
                        <th> Color</th>
                        <th> Clarity</th>
                        <th> Cut</th>
                        <th> Polish</th>
                        <th> Symmetry</th>
                        <th> Fluorescence</th>
                        <th> Certificate</th>
                        <th> Price</th>
                        <th> Table %</th>
                        <th> Total Depth %</th>
                        <th> Measurements</th>
                    </tr>
                    </thead>
                    {diamondDetails && <tbody>{diamondsData}</tbody>}

                </Table>
            </Row>
        </>
    );
}