import Row from "react-bootstrap/Row";
import {Image} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {customDesignAction} from "../../redux/reducers/customDesignRing";
import {RootState} from "../../redux/store";

export default function WhoWill() {
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const shape = [{
        name: "round"
    },
        {
            name: "Princes"
        },
        {
            name: "Cushion"
        },
        {
            name: "Oval"
        },
        {
            name: "Asscher"
        }];
    const shapeHTML = shape.map(i => (
        <div>
            <input
                type="radio"
                name="shape"
                checked={state.customDesign.diamondShape == i.name}
                id={`shape-${i.name}`}
                className="input-hidden"
                onChange={(e) => {
                    if (e.target.checked) {
                        dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({diamondShape: i.name}))
                    } else {
                        dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({diamondShape: i.name}))
                    }
                }}
            />
            <label htmlFor={`shape-${i.name}`} className="diamond-mr-20 border-square">
                <Image height="66px" width="66px"
                       src="https://sarvada.wpstagingserver.com/wp-content/uploads/2023/06/Round.png" alt="Diamond"/>
                <div className="cap-letter">{i.name}</div>
            </label>
        </div>
    ))
    return (

        <Row>
            <div className="text-center">
                <h1>Center Diamond Shape</h1>
            </div>
            <div className="shape-selct text-center">
                {shapeHTML}
            </div>
        </Row>

    );
}
