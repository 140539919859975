import diamondReducer from "./reducers/diamondReducer";
import endUserFilterReducer from "./reducers/endUserFilter";
import wishListReducer from "./reducers/wishlist";
import customDesignReducer from "./reducers/customDesignRing";

import {configureStore} from '@reduxjs/toolkit'


export const store = configureStore({
    reducer: {
        diamondReducer: diamondReducer,
        endUserFilter: endUserFilterReducer,
        wishlist: wishListReducer,
        customDesign: customDesignReducer
    }
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch