import Row from "react-bootstrap/Row";

export default function WhoWill() {

    return (
        <>
            <Row>
                Thank You for requesting US
            </Row>
            <Row>
                <button>Back to home page</button>
            </Row>
        </>
    );
}
