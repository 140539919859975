import {createSlice} from "@reduxjs/toolkit";

interface customDesignDto {
    currentComponent: string,
    whoWill: string,
    centerDiamond: string,
    diamondShape: string,
    caratSize: string,
    colorRange: any[],
    diamondQuality: string,
    shappireShape: string,
    shappireColor: string,
    shappireCarat: any,
    shappireQuality: string,
    quality: string,
    style: string,
    metal: string,
    metalColor: string,
    shappireSize: string,
    centerStoneBudgetMin: string,
    centerStoneBudgetMax: string,
    ringSettingBudgetMin: string,
    ringSettingBudgetMax: string,
    specialDay: string,
    specialDate: string,
    designInspiration: string,
    designFiles: any[],
    inspirationImages: string,
    name: string,
    emailID: string,
    mobileNo: string,
    whatsappNo: string,
    ringSize: string,
    inpirationURLs: string[],
    weddingBand: boolean
}

const initialState: customDesignDto = {
    currentComponent: "firstComponent",
    whoWill: "other",
    centerDiamond: "",
    diamondShape: "",
    caratSize: "",
    colorRange: [],
    diamondQuality: "",
    shappireShape: "",
    shappireColor: "",
    shappireCarat: "",
    shappireQuality: "",
    quality: "",
    style: "",
    metal: "",
    metalColor: "",
    shappireSize: "",
    centerStoneBudgetMin: "",
    centerStoneBudgetMax: "",
    ringSettingBudgetMin: "",
    ringSettingBudgetMax: "",
    specialDay: "Yes",
    specialDate: "",
    designInspiration: "",
    designFiles: [],
    inspirationImages: "",
    name: "",
    emailID: "",
    mobileNo: "",
    whatsappNo: "",
    ringSize: "6",
    inpirationURLs: [],
    weddingBand: false

};
export const customDesign = createSlice({
    name: 'customDesign',
    initialState: initialState,
    reducers: {
        CHANGECOMPONENT: (state, action) => {
            state.currentComponent = action.payload
        },
        ADDCUSTOMDESIGNRINGVALUES: (state, action) => {
            let abc = {};
            if (action.payload.whoWill) state.whoWill = action.payload.whoWill;
            if (action.payload.centerDiamond) state.centerDiamond = action.payload.centerDiamond;
            if (action.payload.diamondShape) state.diamondShape = action.payload.diamondShape;
            if (action.payload.shappireShape) state.shappireShape = action.payload.shappireShape;
            if (action.payload.style) state.style = action.payload.style;
            if (action.payload.metal) state.metal = action.payload.metal;
            if (action.payload.caratSize) state.caratSize = action.payload.caratSize;
            if (action.payload.colorRange) state.colorRange = action.payload.colorRange;
            if (action.payload.diamondQuality) state.diamondQuality = action.payload.diamondQuality;
            if (action.payload.shappireColor) state.shappireColor = action.payload.shappireColor;
            if (action.payload.shappireCarat) state.shappireCarat = action.payload.shappireCarat;
            if (action.payload.shappireQuality) state.shappireQuality = action.payload.shappireQuality;
            if (action.payload.metalColor) state.metalColor = action.payload.metalColor;
            if (action.payload.centerStoneBudgetMin) state.centerStoneBudgetMin = action.payload.centerStoneBudgetMin;
            if (action.payload.centerStoneBudgetMax) state.centerStoneBudgetMax = action.payload.centerStoneBudgetMax;
            if (action.payload.ringSettingBudgetMin) state.ringSettingBudgetMin = action.payload.ringSettingBudgetMin;
            if (action.payload.ringSettingBudgetMax) state.ringSettingBudgetMax = action.payload.ringSettingBudgetMax;
            if (action.payload.specialDay) state.specialDay = action.payload.specialDay;
            if (action.payload.designInspiration) state.designInspiration = action.payload.designInspiration;
            if (action.payload.designFiles) state.designFiles = action.payload.designFiles;
            if (action.payload.name) state.name = action.payload.name;
            if (action.payload.emailID) state.emailID = action.payload.emailID;
            if (action.payload.mobileNo) state.mobileNo = action.payload.mobileNo;
            if (action.payload.whatsappNo) state.whatsappNo = action.payload.whatsappNo;
            if (action.payload.ringSize) state.ringSize = action.payload.ringSize;
            if (action.payload.specialDate) state.specialDate = action.payload.specialDate;
            if (action.payload.inpirationURLs) state.inpirationURLs.push(action.payload.inpirationURLs);

        },
        RESTARTCUSTOMDESIGN: (state, action) => {
            state = initialState;
        },
        REMOVECUSTOMDESIGNRINGVALUES: (state, action) => {
            if (action.payload.centerDiamond) state.centerDiamond = action.payload.centerDiamond
            if (action.payload.diamondShape) state.diamondShape = action.payload.diamondShape
            if (action.payload.style) state.style = action.payload.style
            if (action.payload.metal) state.metal = action.payload.metal
            if (action.payload.metalColor) state.metalColor = action.payload.metalColor
            if (action.payload.diamondQuality) state.diamondQuality = action.payload.diamondQuality
            if (action.payload.colorRange) state.colorRange = action.payload.colorRange
            if (action.payload.caratSize) state.caratSize = action.payload.caratSize
            if (action.payload.centerStoneBudgetMin) state.centerStoneBudgetMin = action.payload.centerStoneBudgetMin;
            if (action.payload.centerStoneBudgetMax) state.centerStoneBudgetMax = action.payload.centerStoneBudgetMax;
            if (action.payload.ringSettingBudgetMin) state.ringSettingBudgetMin = action.payload.ringSettingBudgetMin;
            if (action.payload.ringSettingBudgetMax) state.ringSettingBudgetMax = action.payload.ringSettingBudgetMax;

        }
    }
})

export const customDesignAction = customDesign.actions;
export default customDesign.reducer;