import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {customDesignAction} from "../../redux/reducers/customDesignRing";
import {RootState} from "../../redux/store";

export default function WhoWill() {
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    return (
        <>
            <div className="specialday-width">
                <Row>
                    <div className="text-center">
                        <h1>When’s the Special Day?</h1>
                        <p className="sub-special">If you have a special date in mind that you will need the ring by,
                            let us know. Or if you are just looking around for now, that’s okay too!</p>
                    </div>
                </Row>
                <Row className="one-special">
                    <Col md={12} className="price-down-stone">
                        <select
                            name="day"
                            defaultValue="Please select your option"
                            id="days"
                            value={state.customDesign.specialDay}
                            onChange={e => {
                                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({specialDay: e.target.value}))
                            }}
                        >
                            <option value="Yes">Yes I have specific Date</option>
                            <option value="NoMonth">No Specific Date But I will need ring within a month</option>
                            <option value="No36Month">No Specific Date But sometime in next 3-6 month</option>
                            <option value="NoLooking">I a'm just looking for now</option>
                        </select></Col>
                </Row>
                <Row>
                    <Col md={12} className="p-0">
                        <div className="date-select-spe">
                            {state.customDesign.specialDay == "Yes" && (<input
                                type="date"
                                value={state.customDesign.specialDate}
                                onChange={e => {
                                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({specialDate: e.target.value}))
                                }}
                            />)}
                        </div>
                    </Col>
                </Row>
                <Row className="note-spe">
                    <Col md={12} className="p-0">
                        For Gold Rings, our usual production time is 8-9 working days + delivery time.
                    </Col>
                    <Col md={12} className="p-0">
                        For Platinum Rings, our usual production time is 15-17 working days + delivery time.
                    </Col>
                </Row>
            </div>
        </>
    );
}
