import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {customDesignAction} from "../../redux/reducers/customDesignRing";
import {RootState} from "../../redux/store";

export default function WhoWill({sendData}: any) {
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    return (
        <>
            <Row>
                Your Details
            </Row>
            <Row>
                <Col><input type="text" value={state.customDesign.name} onChange={e => {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({name: e.target.value}))
                }} placeholder="Enter Your Name"/></Col>
                <Col><input type="text" value={state.customDesign.emailID} onChange={e => {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({emailID: e.target.value}))
                }} placeholder="Enter Your EmailID"/></Col>
            </Row>
            <Row>
                <Col><input type="text" value={state.customDesign.mobileNo} onChange={e => {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({mobileNo: e.target.value}))
                }} placeholder="Mobile No"/></Col>
                <Col><input type="text" value={state.customDesign.whatsappNo} onChange={e => {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({whatsappNo: e.target.value}))
                }} placeholder="Whatsapp No."/></Col>
            </Row>
            <Row>
                <Col>
                    <select name="rignSize" id="rignSize" value={state.customDesign.ringSize} onChange={e => {
                        dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({ringSize: e.target.value}))
                    }}>
                        <option value="6">6</option>
                        <option value="8">8</option>
                    </select>
                </Col>
            </Row>
            <Row>
                <Col>
                    <button onClick={sendData}>Submit</button>
                </Col>
            </Row>
            <Row>

                <Col> <input
                    type="checkbox"
                    name="notSure"
                    id={`notSure`}
                    className="input-hidden"
                    onChange={(e) => {

                    }}
                />
                    <label
                        htmlFor={`notSure`}
                        className="diamond-mr-20 group-right-0"
                    >
                        Are You Intrested in matching weeding band as well?
                    </label></Col>
            </Row>
        </>
    );
}
