import React from "react";
import {Col, Image, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {diamondReducerAction} from "../../../redux/reducers/diamondReducer";
import {useNavigate, useParams} from 'react-router-dom';
import env from "../../../env.json";
import {RootState} from "../../../redux/store";

export default function DiamondFinder() {
    const gridimage = ""
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const {productID} = useParams();
    const navigate = useNavigate();

    return (<Row className="GridView">
        {state.diamondReducer.diamonds && state.diamondReducer.diamonds.map((diamond: any, index: number) => (
            <Col xs={3} key={index}>
                <div className="box compare">
                    <div className="compare-wishlist">
                        <input
                            type="checkbox"
                            name="compare"
                            checked={state.diamondReducer.comparisonEarring.filter(item => item.one.diamond_id === diamond.one.diamond_id).length > 0 ? true : false}
                            onClick={(e: any) => e.target.checked === true ? dispatch(diamondReducerAction.ADD_IN_EARRING_COMPARISON((diamond))) : dispatch(diamondReducerAction.REMOVE_IN_EARRING_COMPARISON((diamond)))}
                            disabled={state.diamondReducer.comparisonEarring.length > 4 && state.diamondReducer.comparisonEarring.filter(item => item.one.diamond_id === diamond.one.diamond_id).length === 0}
                        />
                    </div>
                    <div onClick={() => {
                        navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
                    }}> first diamond
                        <Image
                            src={diamond.one.image_file_url ? diamond.one.image_file_url : env.wp_media_path + gridimage}
                            alt=""/>
                        <h5>{diamond.one.size && `${diamond.one.size} Carat`} {diamond.one.clarity && `| ${diamond.one.clarity}`}</h5>
                        <p>
                            {diamond.one.cut && `${diamond.one.cut} Cut`} {diamond.one.fluor_intensity && `${diamond.one.fluor_intensity} Fluorescence`} {diamond.one.lab && `${diamond.one.lab} certified`} {diamond.one.shape && ` | ${diamond.one.shape} Diamond`}
                        </p>
                        <h6>
                            {diamond.one.total_sales_price_in_currency && ` ${diamond.one.total_sales_price_in_currency}`} {diamond.one.total_sales_price_in_currency &&
                            <s>$ {diamond.one.total_sales_price_in_currency}</s>}
                        </h6>

                    </div>
                    <div onClick={() => {
                        navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
                    }}>second diamond
                        <Image
                            src={diamond.two.image_file_url ? diamond.two.image_file_url : env.wp_media_path + gridimage}
                            alt=""/>
                        <h5>{diamond.two.size && `${diamond.two.size} Carat`} {diamond.two.clarity && `| ${diamond.two.clarity}`}</h5>
                        <p>
                            {diamond.two.cut && `${diamond.two.cut} Cut`} {diamond.two.fluor_intensity && `${diamond.two.fluor_intensity} Fluorescence`} {diamond.two.lab && `${diamond.two.lab} certified`} {diamond.two.shape && ` | ${diamond.two.shape} Diamond`}
                        </p>
                        <h6>
                            {diamond.two.total_sales_price_in_currency && ` ${diamond.two.total_sales_price_in_currency}`} {diamond.two.total_sales_price_in_currency &&
                            <s>$ {diamond.two.total_sales_price_in_currency}</s>}
                        </h6>
                    </div>

                </div>
            </Col>
        ))}
    </Row>);
}
