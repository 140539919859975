import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {customDesignAction} from "../../redux/reducers/customDesignRing";
import {useEffect} from "react";
import env from '../../env.json';
import indFunction from '../common/IndFunctions';
import {RootState} from "../../redux/store";
import {callRepnetAPI} from "../../utils/general";

export default function WhoWill() {
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const caratSize = [{name: "0.30"}, {name: "0.40"}, {name: "0.50"}, {name: "0.60"}, {name: "0.70"}];
    const colorRange = [{index: 1, name: "D-F"}, {index: 2, name: "G-H"}, {index: 3, name: "I-J"}, {
        index: 4,
        name: "K-L"
    }, {index: 5, name: "M& Above"}];
    const diamondQuality = [{name: "FL-IF"}, {name: "VVS-1"}, {name: "VVS2"}, {name: "VS1"}, {name: "VS2"}, {name: "SI1"}, {name: "SI2"}, {name: "I1 AND ABOVE"}];
    const caratHTML = caratSize.map((i, index) => (<div key={index}>
        <input
            type="radio"
            name="carat"
            value={i.name}
            checked={state.customDesign.caratSize == i.name}
            id={`carat-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({caratSize: i.name}))
                } else {
                    dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({caratSize: i.name}))
                }
            }}
        />
        <label
            htmlFor={`carat-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract"
        >
            {i.name}
        </label>
    </div>));
    const colorRangeHTML = colorRange.map((i, index) => (<div key={index}>
        <input
            type="checkbox"
            name="colorRange"
            checked={state.customDesign.colorRange.map(k => k.name).indexOf(i.name) != -1 ? true : false}
            id={`colorRange-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    const abc = state.customDesign.colorRange;
                    for (var j = 0; j < abc.length; j++) {
                        if (abc[j] === "notSure") {
                            abc.splice(j, 1);
                        }
                    }
                    abc.push(i)
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({colorRange: abc}))
                } else {
                    const temp = state.customDesign.colorRange;
                    for (var j = 0; j < temp.length; j++) {
                        if (temp[j].name === i.name) {
                            temp.splice(j, 1);
                        }
                        dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({colorRange: temp}))
                    }
                }
            }}
        />
        <label
            htmlFor={`colorRange-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract color-range-width"
        >
            {i.name}
        </label>
    </div>));
    const diamondQualityHTML = diamondQuality.map((i, index) => (<span key={index}>
        <input
            type="radio"
            name="diamondQuality"
            value={i.name}
            checked={state.customDesign.diamondQuality == i.name}
            id={`diamondQuality-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({diamondQuality: i.name}))
                } else {
                    dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({diamondQuality: i.name}))
                }
            }}
        />
        <label
            htmlFor={`diamondQuality-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract"
        >
            {i.name}
        </label>
    </span>));
    useEffect(() => {
        const abc = new Promise(async (resolve, reject) => {

            const data = await callRepnetAPI(env.newRepNetURL, state.diamondReducer.access_token, {
                request: {
                    header: {
                        // username: env.username,
                        // password: env.password,
                    },
                    body: indFunction.customDesignDiamondFilter(state.customDesign, "Asc"),
                }

            })
            if (data) {
                if (data.search_results) {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({centerStoneBudgetMin: data.diamonds[0].total_sales_price_in_currency}))


                }
            }

            const second = await callRepnetAPI(env.newRepNetURL, state.diamondReducer.access_token, indFunction.customDesignDiamondFilter(state.customDesign, "Desc"))
            if (second) {
                if (second.search_results) {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({centerStoneBudgetMin: second.diamonds[0].total_sales_price_in_currency}))


                }
            }


        })

        abc.then()

    }, [state.customDesign.diamondShape, state.customDesign.caratSize, JSON.stringify(state.customDesign.colorRange), state.customDesign.diamondQuality])
    return (
        <>
            <Row>
                <div className="text-center">
                    <h1>Centre Diamond</h1>
                </div>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="inner-label">Carat</div>
                </Col>
                <Col md={12}>
                    <div className="shape-selct-diamond text-center">
                        {caratHTML}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="inner-label">Color Range (More than one can be selected)</div>
                </Col>
                <Col md={12}>
                    <div className="shape-selct-diamond text-center">
                        {colorRangeHTML}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="inner-label">Choose diamond quality (each diamond size has different price range)
                    </div>
                </Col>
                <Col md={12}>
                    <div className="shape-selct-diamond text-center">
                        {diamondQualityHTML}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <input
                        type="checkbox"
                        name="notSure"
                        checked={state.customDesign.diamondQuality == "notSure"}
                        id={`notSure`}
                        className="input-hidden"
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({diamondQuality: "notSure"}))
                                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({colorRange: ["notSure"]}))
                                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({caratSize: "notSure"}))
                            } else {
                                dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({diamondQuality: "notSure"}))
                                dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({colorRange: "notSure"}))
                                dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({caratSize: "notSure"}))

                            }
                        }}
                    />
                    <label
                        htmlFor={`notSure`}
                        className="diamond-mr-20 group-right-0"
                    >
                        Not Sure Yet
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    Minimum Price {state.customDesign.centerStoneBudgetMin}
                </Col>
                <Col>
                    Maximum Price {state.customDesign.centerStoneBudgetMax}
                </Col>
            </Row>
        </>
    );
}
