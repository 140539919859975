import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import {Row} from "react-bootstrap";
import env from "../../../env.json";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from 'axios';
import {diamondReducerAction} from '../../../redux/reducers/diamondReducer'
import {RootState} from "../../../redux/store";

export default function Whishlist() {
    const {productID} = useParams();
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [diamondDetails, setDiamondDetails] = useState<any>([]);

    function axosFunc(diamondID: string) {
        return new Promise((resolve, reject) => {
            const options = {
                method: 'POST',
                url: env.newRepNetSingleURL,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': ""
                },
                data: JSON.stringify({
                    request: {
                        header: {
                            // username: env.username,
                            // password: env.password,
                        },
                        body: {
                            diamond_id: parseInt(diamondID)
                        },
                    }
                }),
            };
            axios(options).then((res) => {

                if (res.data.response.header.error_message !== "" && res.data.response.header.error_message === "No Diamond was found") {
                    resolve({error: res.data.response.header.error_message})
                } else {
                    resolve(res.data.response.body.diamond)
                }
            })
                .catch(error => {
                    resolve({error: error})
                });
        })

    }

    function recursiveData(index: number, diamonds: any, diamondDetails: any, cb: Function) {
        axosFunc((diamonds[index].one)).then((resOne: any) => {
            if (resOne.error) {
                return 0;
            } else {

                axosFunc((diamonds[index].two)).then((resTwo: any) => {
                    if (resTwo.error) {
                        return 0;
                    } else {
                        diamondDetails.push({one: resOne, two: resTwo})
                        index = index + 1
                        if (index < diamonds.length) {
                            recursiveData(index, diamonds, diamondDetails, cb);
                        } else {
                            cb(diamondDetails)
                        }
                    }

                })
            }
        })
    }

    useEffect(() => {
        recursiveData(0, state.wishlist.earringDiamonds, [], (d: any) => {
            setDiamondDetails([...d])
        })
    }, [])
    const diamondsData = diamondDetails ? diamondDetails.map((diamond: any, index: number) => (
        <tr key={index}>
            <td>
                <input
                    type="checkbox"
                    name="compare"
                    checked={state.diamondReducer.comparisonEarring.filter(item => item.one.diamond_id === diamond.one.diamond_id).length > 0 ? true : false}
                    onClick={(e: any) => e.target.checked === true ? dispatch(diamondReducerAction.ADD_IN_COMPARISON((diamond))) : dispatch(diamondReducerAction.REMOVE_IN_COMPARISON((diamond)))}
                    disabled={state.diamondReducer.comparisonEarring.length > 4 && state.diamondReducer.comparisonEarring.filter(item => item.one.diamond_id === diamond.one.diamond_id).length === 0}

                />
            </td>
            <td> {diamond.one.shape} <br/> {diamond.two.shape} </td>
            <td> {diamond.one.size} <br/> {diamond.two.size} </td>
            <td> {diamond.one.color} <br/> {diamond.two.color} </td>
            <td> {diamond.one.clarity} <br/> {diamond.two.clarity} </td>
            <td> {diamond.one.cut} <br/> {diamond.two.cut} </td>
            <td> {diamond.one.polish} <br/> {diamond.two.polish} </td>
            <td> {diamond.one.symmetry} <br/> {diamond.two.symmetry} </td>
            <td> {diamond.one.fluor_intensity} <br/> {diamond.two.fluor_intensity} </td>
            <td> {diamond.one.lab} <br/> {diamond.two.lab} </td>
            <td> {diamond.one.total_sales_price_in_currency} <br/> {diamond.two.total_sales_price_in_currency} </td>
            <td> {diamond.one.table_percent} <br/> {diamond.two.table_percent} </td>
            <td> {diamond.one.depth_percent} <br/> {diamond.two.depth_percent} </td>
            <td> {diamond.one.meas_length}x{diamond.one.meas_width}x{diamond.one.meas_depth}
                <br/> {diamond.two.meas_length}x{diamond.two.meas_width}x{diamond.two.meas_depth} </td>
        </tr>
    )) : <p>No Whishlist Data Found</p>;

    return (
        <>
            <Row className="ListView">
                <Table
                    responsive
                    striped
                    bordered
                    hover
                    className="ext-table text-center"
                >
                    <thead>
                    <tr>
                        <th> Add To <br/> Compare</th>
                        <th> Shape</th>
                        <th> Carat</th>
                        <th> Color</th>
                        <th> Clarity</th>
                        <th> Cut</th>
                        <th> Polish</th>
                        <th> Symmetry</th>
                        <th> Fluorescence</th>
                        <th> Certificate</th>
                        <th> Price</th>
                        <th> Table %</th>
                        <th> Total Depth %</th>
                        <th> Measurements</th>
                    </tr>
                    </thead>
                    {diamondDetails && <tbody>{diamondsData}</tbody>}

                </Table>
            </Row>
        </>
    );
}