import "./diamond-finder.css";
import Filters from "../components/filter/filters";

import DiamondListing from '../components/listings/diamondListing'
import env from '../env.json';
import {useDispatch, useSelector} from "react-redux";
import {diamondReducerAction} from "../redux/reducers/diamondReducer";
import indFunction from '../components/common/IndFunctions'
import {useEffect} from "react";
import {useLocation, useSearchParams} from 'react-router-dom';
import {callRepnetAPI, callWordpressAPI} from "../utils/general";
import {RootState} from "../redux/store";

export default function DiamondFinder() {
    // const apiTokenData = useContext(ApiContext);

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const refreshData = async () => {
        if (state.endUserFilter.search) {
            const data: any = await callRepnetAPI(env.newRepNetSingleURL, state.diamondReducer.access_token, {
                diamond_id: parseInt(state.endUserFilter.search)
            })
            if (data && data.diamond) {
                dispatch(diamondReducerAction.ADD_DIAMONDS_DATA({
                    diamonds: [data.diamond],
                    totalDiamonds: 1,
                    returnedDiamonds: 1
                }))
            }else{
                dispatch(diamondReducerAction.ADD_DIAMONDS_DATA({
                    diamonds: [],
                    totalDiamonds: 0,
                    returnedDiamonds: 0
                }))
            }

        } else {
            const data: any = await callRepnetAPI(env.newRepNetURL, state.diamondReducer.access_token, indFunction.changeFilterToRepnet(state.endUserFilter, state.endUserFilter.filterMainData, state.diamondReducer.colorTab))
            if (data) {
                dispatch(diamondReducerAction.ADD_DIAMONDS_DATA({
                    diamonds: data.diamonds,
                    totalDiamonds: data.search_results.total_diamonds_found,
                    returnedDiamonds: data.search_results.diamonds_returned
                }))
            }

        }
    }

    useEffect(() => {
        const abc = new Promise(async (resolve) => {
            let loc: string = location.pathname;
            loc = loc.substring(1, loc.length)
            loc = loc.substring(0, loc.indexOf('/'));
            dispatch(diamondReducerAction.ADDJOURNEY(loc))
            if (state.diamondReducer.access_token) {
                await refreshData()
            }
        })
        abc.then()

    }, [state.endUserFilter, state.endUserFilter.filterMainData, state.diamondReducer.access_token])

    useEffect(() => {
        const abc = new Promise(async (resolve) => {
            const res: any = await callWordpressAPI(env.RepNetAuthTokenUrl)
            dispatch(diamondReducerAction.ADD_API_TOKEN(res.access_token))
        })
        abc.then()

    }, [])

    return (<div className="shop-conflict-free">
        <div className="container">
            <Filters></Filters>
            <DiamondListing/>
        </div>
    </div>);
}
