import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {customDesignAction} from "../../redux/reducers/customDesignRing";
import indFunction from '../common/IndFunctions';
import {useEffect} from "react";
import env from '../../env.json';
import {RootState} from "../../redux/store";
import {callRepnetAPI} from "../../utils/general";

export default function WhoWill() {
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const caratSize = [{index: "0-1", name: "Below 1 Carat"}, {
        index: "1-1.50",
        name: "1-1.50 Carat"
    }, {index: "1.5-1.99", name: "1.5-1.99 Carat"}, {index: "2-2.99", name: "2-2.99 Carat"}, {
        index: "3-5",
        name: "3+ Carat"
    }];
    const colorRange = [{name: "Blue"}, {name: "Teal"}, {name: "Peach"}, {name: "Pink"}, {name: "Purple"}];
    const diamondQuality = [{name: "Standard"}, {name: "Delux"}, {name: "Premium"}];
    const caratHTML = caratSize.map((i, index) => (<span key={index}>
        <input
            type="radio"
            name="carat"
            value={i.name}
            checked={state.customDesign.shappireCarat.name == i.name}
            id={`carat-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({shappireCarat: i}))
                } else {
                    dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({shappireCarat: i}))
                }
            }}
        />
        <label
            htmlFor={`carat-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract"
        >
            {i.name}
        </label>
    </span>));
    const colorRangeHTML = colorRange.map((i, index) => (<div key={index}>
        <input
            type="radio"
            name="colorRange"
            value={i.name}
            checked={state.customDesign.shappireColor == i.name}
            id={`colorRange-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({shappireColor: i.name}))
                } else {
                    dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({shappireColor: i.name}))
                }
            }}
        />
        <label
            htmlFor={`colorRange-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract color-shappire"
        >
            <i className="circle-color color-gold"></i>{i.name}
        </label>
    </div>));
    const diamondQualityHTML = diamondQuality.map((i, index) => (<div key={index}>
        <input
            type="radio"
            name="diamondQuality"
            value={i.name}
            checked={state.customDesign.shappireQuality == i.name}
            id={`diamondQuality-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({shappireQuality: i.name}))
                } else {
                    dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({shappireQuality: i.name}))
                }
            }}
        />
        <label
            htmlFor={`diamondQuality-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract"
        >
            {i.name}
        </label>
    </div>));
    useEffect(() => {
        const abc = new Promise(async (resolve, reject) => {
            if (state.customDesign.shappireColor == "notSure" || state.customDesign.shappireCarat == "notSure" || state.customDesign.shappireQuality == "notSure") {

            } else {
                const data = await callRepnetAPI(env.newRepNetURL, state.diamondReducer.access_token, {
                    request: {
                        header: {
                            // username: env.username,
                            // password: env.password,
                        },
                        body: indFunction.customDesignShappireFilter(state.customDesign, "Asc"),
                    }
                })

                if (data) {
                    const dataOne = data.body;
                    if (dataOne.search_results) {
                        dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({centerStoneBudgetMin: dataOne.diamonds[0].total_sales_price_in_currency}))

                    } else {
                        console.log('no data coming')
                    }
                }

                const second = await callRepnetAPI(env.newRepNetURL, state.diamondReducer.access_token, {
                    request: {
                        header: {
                            // username: env.username,
                            // password: env.password,
                        },
                        body: indFunction.customDesignShappireFilter(state.customDesign, "Desc"),
                    }

                })

                if (second) {
                    const data = second;
                    if (data.search_results) {
                        dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({centerStoneBudgetMax: data.diamonds[0].total_sales_price_in_currency}))
                    } else {
                    }
                }

            }
        })


    }, [state.customDesign.shappireShape, state.customDesign.shappireCarat, state.customDesign.shappireColor, state.customDesign.shappireQuality])

    return (
        <>
            <Row>
                <div className="text-center"><h1>Centre Shappire</h1></div>

            </Row>
            <Row>
                <Col md={12}>
                    <div className="inner-label">Color</div>
                </Col>
                <Col md={12}>
                    <div className="shape-selct-diamond text-center">
                        {colorRangeHTML}
                    </div>
                </Col>

            </Row>
            <Row>
                <Col md={12}>
                    <div className="inner-label"> CaratSize</div>
                </Col>
                <Col md={12}>
                    <div className="shape-selct-diamond text-center">
                        {caratHTML}
                    </div>
                </Col>

            </Row>
            <Row>
                <Col md={12}>
                    <div className="inner-label"> Quality</div>
                </Col>
                <Col md={12}>
                    <div className="shape-selct-diamond text-center">{diamondQualityHTML}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <input
                        type="checkbox"
                        name="notSure"
                        checked={state.customDesign.shappireColor == "notSure"}
                        id={`notSure`}
                        className="input-hiddens"
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({shappireColor: "notSure"}))
                                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({shappireCarat: "notSure"}))
                                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({shappireQuality: "notSure"}))
                            } else {
                                dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({shappireColor: "notSure"}))
                                dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({shappireCarat: "notSure"}))
                                dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({shappireQuality: "notSure"}))

                            }
                        }}
                    />
                    <label
                        htmlFor={`notSure`}
                        className="diamond-mr-20 group-right-0"
                    >
                        Not Sure Yet
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    Minimum Price {state.customDesign.centerStoneBudgetMin}
                </Col>
                <Col>
                    Maximum Price {state.customDesign.centerStoneBudgetMax}
                </Col>
            </Row>
        </>
    );
}
