import {createSlice} from "@reduxjs/toolkit";

type fancy_colorDto = {
    color_category: string,
    Name: string
}

type cutDto = {
    name: string
}

export type filterMainDataStateDto = {
    shape: any[],
    carat: any,
    fancy_color: fancy_colorDto[],
    dominantColors: any[],
    fancy_diamond_color: any[],
    fancy_color_intensity: any[],
    colorIntensity: any[],
    clarity: cutDto[],
    cut: cutDto[],
    polish: any[],
    symmetry: {
        min: number,
        max: number
    },
    fluorescence: any[],
    certificate: any[],
    price: any,
    table: any,
    total_depth: any,
    ratio: any,
    totaldepth: any,
    measurements: any,
    LWRatio: any,
    shademilky: any[]
}
type certificateDto = {
    name: string
}
export type endUserFilterInitialStateDto = {
    shape: string[],
    carat: any,
    searchType: string,
    fancy_color: any[],
    dominantColors: any[],
    colorIntensity: any[],
    fancy_diamond_color: any[],
    fancy_color_intensity: any[],
    clarity: {
        min: number,
        max: number
    },
    cut: { min: number, max: number },
    polish: any[],
    symmetry: any[],
    fluorescence: any[],
    certificate: certificateDto[],
    price: any,
    table: any,
    total_depth: any,
    ratio: any,
    totaldepth: any,
    labs: any
    measurements: any,
    fluorescence_intensities: any,
    LWRatio: any,
    shademilky: any[],
    page_number: number,
    page_size: number,
    sort_by: string,
    sort_direction: string,
    search: string,
    earringSearch: boolean,
    filterMainData: filterMainDataStateDto,
}

const endUserFilterInitialState: endUserFilterInitialStateDto = {
    shape: [],
    carat: {},
    searchType: "SKU",
    fancy_color: [],
    dominantColors: [],
    colorIntensity: [],
    fancy_diamond_color: [],
    fancy_color_intensity: [],
    clarity: {
        min: 0,
        max: 0
    },
    cut: {
        min: 0,
        max: 0
    },
    polish: [],
    symmetry: [],
    fluorescence: [],
    certificate: [],
    price: {},
    table: {},
    total_depth: {},
    ratio: {},
    labs: "",
    totaldepth: {},
    measurements: {},
    LWRatio: {},
    fluorescence_intensities: "",
    shademilky: [],
    page_number: 1,
    page_size: 40,
    sort_by: "price",
    sort_direction: "Asc",
    search: "",
    earringSearch: false,
    filterMainData: {
        shape: [],
        carat: {},
        fancy_color: [],
        dominantColors: [],
        fancy_diamond_color: [],
        fancy_color_intensity: [],
        colorIntensity: [],
        clarity: [],
        cut: [],
        polish: [],
        symmetry: {min: 0, max: 0},
        fluorescence: [],
        certificate: [],
        price: {},
        table: {},
        total_depth: {},
        ratio: {},
        totaldepth: {},
        measurements: {},
        LWRatio: {},
        shademilky: []
    },
}

export const endUserFilterSlice = createSlice({
    name: "endUserFilter",
    initialState: endUserFilterInitialState,
    reducers: {
        FIRSTENDUSERFILTER: (state: endUserFilterInitialStateDto, action) => {
            if (action.payload.productFilter.shape) state.shape = action.payload.productFilter.shape
            if (action.payload.productFilter.carat) state.carat = action.payload.productFilter.carat
            if (action.payload.productFilter.fancy_color) state.fancy_color = action.payload.productFilter.fancy_color
            if (action.payload.productFilter.dominantColors) state.dominantColors = action.payload.productFilter.dominantColors
            if (action.payload.productFilter.colorIntensity) state.colorIntensity = action.payload.productFilter.colorIntensity
            if (action.payload.productFilter.clarity) {
                state.clarity = {
                    min: action.payload.productFilter.clarity.min,
                    max: action.payload.productFilter.clarity.max,
                }

            }
            if (action.payload.productFilter.cut) {
                state.cut = {
                    min: action.payload.productFilter.cut.min,
                    max: action.payload.productFilter.cut.max,
                }
            }

            if (action.payload.productFilter.fancy_diamond_color) state.fancy_diamond_color = action.payload.productFilter.fancy_diamond_color
            if (action.payload.productFilter.fancy_color_intensity) state.fancy_color_intensity = action.payload.productFilter.fancy_color_intensity
            if (action.payload.productFilter.polish) state.polish = action.payload.productFilter.polish
            if (action.payload.productFilter.symmetry) state.symmetry = action.payload.productFilter.symmetry
            if (action.payload.productFilter.fluorescence) state.fluorescence = action.payload.productFilter.fluorescence
            if (action.payload.productFilter.price) state.price = action.payload.productFilter.price
            if (action.payload.productFilter.table) state.table = action.payload.productFilter.table
            if (action.payload.productFilter.totaldepth) state.totaldepth = action.payload.productFilter.totaldepth
            if (action.payload.productFilter.measurements) state.measurements = action.payload.productFilter.measurements
            if (action.payload.productFilter.LWRatio) state.LWRatio = action.payload.productFilter.LWRatio
            if (action.payload.productFilter.shademilky) state.shademilky = action.payload.productFilter.shademilky
            if (action.payload.productFilter.total_depth) state.total_depth = action.payload.productFilter.total_depth
            if (action.payload.productFilter.certificate) {
                state.certificate = action.payload.productFilter.certificate.map((i: any) => {
                    return {name: i}
                })
            }

        },
        ENDUSERFILTER: (state: endUserFilterInitialStateDto, action) => {
            if (action.payload.shape != undefined) state.shape = action.payload.shape
            if (action.payload.carat != undefined) {
                state.carat = action.payload.carat
            }
            if (action.payload.fancy_color != undefined) {
                state.fancy_color = action.payload.fancy_color
            }
            if (action.payload.fancy_diamond_color != undefined) {
                state.fancy_diamond_color = action.payload.fancy_diamond_color
            }
            if (action.payload.fancy_color_intensity != undefined) {
                state.fancy_color_intensity = action.payload.fancy_color_intensity
            }
            if (action.payload.dominantColors != undefined) {
                state.dominantColors = action.payload.dominantColors
            }
            if (action.payload.colorIntensity != undefined) {
                state.colorIntensity = action.payload.colorIntensity
            }
            if (action.payload.clarity != undefined) {
                state.clarity = action.payload.clarity
            }
            if (action.payload.cut != undefined) {
                state.cut = action.payload.cut
            }
            if (action.payload.polish != undefined) {
                state.polish = action.payload.polish
            }
            if (action.payload.symmetry != undefined) {
                state.symmetry = action.payload.symmetry
            }
            if (action.payload.fluorescence != undefined) {
                state.fluorescence = action.payload.fluorescence
            }
            if (action.payload.price != undefined) {
                state.price = action.payload.price
            }
            if (action.payload.table != undefined) {
                state.table = action.payload.table
            }
            if (action.payload.measurements != undefined) {
                state.measurements = action.payload.measurements
            }
            if (action.payload.LWRatio != undefined) {
                state.LWRatio = action.payload.LWRatio
            }
            if (action.payload.shademilky != undefined) {
                state.shademilky = action.payload.shademilky
            }
            if (action.payload.total_depth != undefined) {
                state.total_depth = action.payload.total_depth
            }
            if (action.payload.certificate != undefined) {
                state.certificate = action.payload.certificate
            }
            if (action.payload.page_number != undefined) {
                state.page_number = action.payload.page_number
            }
            if (action.payload.earringSearch != undefined) {
                state.earringSearch = action.payload.earringSearch
            }
            if (action.payload.searchType != undefined) {
                state.searchType = action.payload.searchType
            }
            if (action.payload.search != undefined) {
                state.search = action.payload.search
            }
            return state;
        },
        SORTING: (state: endUserFilterInitialStateDto, action) => {
            state.sort_by = action.payload
        },
        ADDFILTERS: (state, action) => {

            if (action.payload.shape != undefined) state.filterMainData.shape = action.payload.shape
            if (action.payload.carat != undefined) state.filterMainData.carat = action.payload.carat
            if (action.payload.fancy_color != undefined) state.filterMainData.fancy_color = action.payload.fancy_color
            if (action.payload.dominantColors != undefined) state.filterMainData.dominantColors = action.payload.dominantColors
            if (action.payload.fancy_diamond_color != undefined) state.filterMainData.fancy_diamond_color = action.payload.fancy_diamond_color
            if (action.payload.fancy_color_intensity != undefined) state.filterMainData.fancy_color_intensity = action.payload.fancy_color_intensity
            if (action.payload.colorIntensity != undefined) state.filterMainData.colorIntensity = action.payload.colorIntensity
            if (action.payload.clarity != undefined) state.filterMainData.clarity = action.payload.clarity
            if (action.payload.cut != undefined) state.filterMainData.cut = action.payload.cut
            if (action.payload.polish != undefined) state.filterMainData.polish = action.payload.polish
            if (action.payload.symmetry != undefined) state.filterMainData.symmetry = action.payload.symmetry
            if (action.payload.fluorescence != undefined) state.filterMainData.fluorescence = action.payload.fluorescence
            if (action.payload.certificate != undefined) state.filterMainData.certificate = action.payload.certificate
            if (action.payload.price != undefined) state.filterMainData.price = action.payload.price
            if (action.payload.table != undefined) state.filterMainData.table = action.payload.table
            if (action.payload.totaldepth != undefined) state.filterMainData.totaldepth = action.payload.totaldepth
            if (action.payload.measurements != undefined) state.filterMainData.measurements = action.payload.measurements
            if (action.payload.LWRatio != undefined) state.filterMainData.LWRatio = action.payload.LWRatio
            if (action.payload.shademilky != undefined) state.filterMainData.shademilky = action.payload.shademilky
            if (action.payload.total_depth != undefined) state.filterMainData.total_depth = action.payload.total_depth


        },
    }
});

export const endUserFilterAction = endUserFilterSlice.actions;
export default endUserFilterSlice.reducer;