import React, {useState} from "react";
import {Image} from "react-bootstrap";

const round = ""
const princess = ""
const cushion = ""

const QuizShape = ({onStateChange}: any) => {

    const [shapes, setShapes] = useState<any>([]);

    const handleShapeChange = (e: any) => {
        // console.log(e.target.checked, e.target.value);
        if (e.target.checked) {
            let temp = shapes;
            temp.push(e.target.value);
            setShapes(temp);
            onStateChange(temp);
        } else {
            for (var i = 0; i < shapes.length; i++) {
                if (shapes[i] === e.target.value) {
                    shapes.splice(i, 1);
                }
                setShapes(shapes);
                onStateChange(shapes);
            }
        }
    }

    return (
        <>
            <ul id="progresssteps">
                <li className="active"></li>
                <li className=""></li>
                <li></li>
                <li></li>
            </ul>
            <div className="wht-say m-top-60">
                <h4 className="quiz-heading">What shape do you prefer?</h4>
                <div className="diamond-shape-filter-shop">
                    <div className="diamond-mr-20"></div>
                    <div className="select-shape-1">
                        <input type="checkbox" name="modalshape" id="modal-shape-Round-quiz" className="input-hidden"
                               value="Round" onChange={handleShapeChange}/>
                        <label htmlFor="modal-shape-Round-quiz" className="diamond-mr-20 diamond-box-btn">
                            <Image src={round} alt="Round" className="shape-img-box"/>
                        </label>
                        <span>Round</span>
                    </div>
                    <div className="select-shape-1">
                        <input type="checkbox" name="modalshape" id="modal-shape-Princess" className="input-hidden"
                               value="Princess" onChange={handleShapeChange}/>
                        <label htmlFor="modal-shape-Princess" className="diamond-mr-20 diamond-box-btn">
                            <Image src={princess} alt="Princess" className="shape-img-box"/>
                        </label>
                        <span>Princess</span>
                    </div>
                    <div className="select-shape-1">
                        <input type="checkbox" name="modalshape" id="modal-shape-Cushion" className="input-hidden"
                               value="Cushion" onChange={handleShapeChange}/>
                        <label htmlFor="modal-shape-Cushion" className="diamond-mr-20 diamond-box-btn">
                            <Image src={cushion} alt="Cushion" className="shape-img-box"/>
                        </label>
                        <span>Cushion</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuizShape;