import {Dropdown, DropdownButton} from "react-bootstrap";
import {endUserFilterAction} from "../../../redux/reducers/endUserFilter";
import {diamondReducerAction} from "../../../redux/reducers/diamondReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

export default function ModifyColumns() {
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    function modifyColumns(e: any, columnName: string) {
        let abc: any = {};
        abc[columnName] = e.target.checked;
        dispatch(diamondReducerAction.COLUMNS_MANAGER(abc))
    }

    function handleSort(sortOption: string) {
        dispatch(endUserFilterAction.SORTING(sortOption))
    }

    return (
        <>
            <Dropdown className="drop-down-left">
                {state.diamondReducer.listingType !== "accordian" ?
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        className="modify-column-data"
                    >
                        Modify Columns
                    </Dropdown.Toggle> : ''
                }

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1" disabled={true}>
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"
                            checked={true}
                            disabled={true}
                            // onChange={(e) => modifyColumns(e, "compare")}
                        />
                        Add to compare
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" disabled={true}>
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"
                            checked={state.diamondReducer.columnsManager.shape}
                            disabled={true}
                            onChange={(e) => modifyColumns(e, "shape")}
                        />
                        Shape
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1" disabled={true}>
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.carat}
                            disabled={true}
                            onChange={(e) => modifyColumns(e, "carat")}
                        />
                        Carat
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" disabled={true}>
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.color}
                            disabled={true}
                            onChange={(e) => modifyColumns(e, "color")}
                        />
                        Color
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" disabled={true}>
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.clearity}
                            disabled={true}
                            onChange={(e) => modifyColumns(e, "clearity")}
                        />
                        Clarity
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.cut}
                            onChange={(e) => modifyColumns(e, "cut")}
                        />
                        Cut
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.polish}
                            onChange={(e) => modifyColumns(e, "polish")}
                        />{" "}
                        Polish
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.symmetry}
                            onChange={(e) => modifyColumns(e, "symmetry")}
                        />
                        Symmetry
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.fluorescene}
                            onChange={(e) => modifyColumns(e, "fluorescene")}
                        />
                        Fluorescene
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.certificate}
                            onChange={(e) => modifyColumns(e, "certificate")}
                        />
                        Certificate
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1" disabled={true}>
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.price}
                            disabled={true}
                            onChange={(e) => modifyColumns(e, "price")}
                        />
                        Price
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.table}
                            onChange={(e) => modifyColumns(e, "table")}
                        />
                        Table %
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.totaldepth}
                            onChange={(e) => modifyColumns(e, "totaldepth")}
                        />
                        Total Depth %
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.measurements}
                            onChange={(e) => modifyColumns(e, "measurements")}
                        />
                        Measurements
                    </Dropdown.Item>

                    {/* <Dropdown.Item href="#/action-3">
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.LWRatio}
                            onChange={(e) => modifyColumns(e, "LWRatio")}
                        />
                        L/W Ratio
                    </Dropdown.Item> */}

                    {/* <Dropdown.Item href="#/action-3">
                        <input
                            type="checkbox"
                            className="check-right"
                            name="value"

                            checked={state.diamondReducer.columnsManager.ShadeMilky}
                            onChange={(e) => modifyColumns(e, "ShadeMilky")}
                        />
                        Shade/Milky
                    </Dropdown.Item> */}

                </Dropdown.Menu>
            </Dropdown>

            <DropdownButton
                id="sort-dropdown"
                title={`Sort by ${state.endUserFilter.sort_by}`}
                className="sort-down-right"
            >
                <Dropdown.Item onClick={() => handleSort("size")}>
                    Sort by Size
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSort("price")}>
                    Sort by Price
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSort("shape")}>
                    Sort by Shape
                </Dropdown.Item>
            </DropdownButton>
        </>
    )
}