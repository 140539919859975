import React from "react";

const ExpertFluore = ({fluore}: any) => {
    switch (fluore) {
        case "None":
            return <>
                <p>
                    Fluorescence is often overlooked by most people as they tend to
                    focus only on the 4C’s. However, this is extremely important and
                    affects the visual apperance of the diamond as well as the price
                    greatly.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Diamonds with None/Faint fluorescence are recommended as there
                        is no visual effect.
                    </li>
                    <li>
                        Diamonds with Strong/Very Strong fluorescence should be avoided
                        as they will appear milky/hazy, like having an oily effect, in
                        natural sunlight, even though they will be almost half price as
                        compared to diamonds with none fluorescence. Diamonds with
                        medium fluorescence may or may not have this effect and a
                        physical inspection by our experts can confirm this.
                    </li>
                    <li>
                        It is a common misconception based on advice by some so called
                        online diamond experts that for diamonds below J color,
                        strong/very strong fluorescence should be selected to make the
                        diamond look “whiter”. It is not true, as a milky/hazy diamond
                        can look “whiter” but you want the diamond to sparkle and shine,
                        not look “Milky/White” and such diamonds will always have
                        visiibly lesser sparkle and shine.
                    </li>
                </ul>
                <a href='#'>Learn More</a>
            </>

        case "Very Slight":
            return <>
                <p>
                    Fluorescence is often overlooked by most people as they tend to
                    focus only on the 4C’s. However, this is extremely important and
                    affects the visual apperance of the diamond as well as the price
                    greatly.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Diamonds with None/Faint fluorescence are recommended as there
                        is no visual effect.
                    </li>
                    <li>
                        Diamonds with Strong/Very Strong fluorescence should be avoided
                        as they will appear milky/hazy, like having an oily effect, in
                        natural sunlight, even though they will be almost half price as
                        compared to diamonds with none fluorescence. Diamonds with
                        medium fluorescence may or may not have this effect and a
                        physical inspection by our experts can confirm this.
                    </li>
                    <li>
                        It is a common misconception based on advice by some so called
                        online diamond experts that for diamonds below J color,
                        strong/very strong fluorescence should be selected to make the
                        diamond look “whiter”. It is not true, as a milky/hazy diamond
                        can look “whiter” but you want the diamond to sparkle and shine,
                        not look “Milky/White” and such diamonds will always have
                        visiibly lesser sparkle and shine.
                    </li>
                </ul>
                <a href='#'>Learn More</a>
            </>

        case "Medium":
            return <>
                <p>
                    Fluorescence is often overlooked by most people as they tend to
                    focus only on the 4C’s. However, this is extremely important and
                    affects the visual apperance of the diamond as well as the price
                    greatly.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Diamonds with None/Faint fluorescence are recommended as there
                        is no visual effect.
                    </li>
                    <li>
                        Diamonds with Strong/Very Strong fluorescence should be avoided
                        as they will appear milky/hazy, like having an oily effect, in
                        natural sunlight, even though they will be almost half price as
                        compared to diamonds with none fluorescence. Diamonds with
                        medium fluorescence may or may not have this effect and a
                        physical inspection by our experts can confirm this.
                    </li>
                    <li>
                        It is a common misconception based on advice by some so called
                        online diamond experts that for diamonds below J color,
                        strong/very strong fluorescence should be selected to make the
                        diamond look “whiter”. It is not true, as a milky/hazy diamond
                        can look “whiter” but you want the diamond to sparkle and shine,
                        not look “Milky/White” and such diamonds will always have
                        visiibly lesser sparkle and shine.
                    </li>
                </ul>
                <a href='#'>Learn More</a>
            </>

        case "Strong":
            return <>
                <p>
                    Fluorescence is often overlooked by most people as they tend to
                    focus only on the 4C’s. However, this is extremely important and
                    affects the visual apperance of the diamond as well as the price
                    greatly.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Diamonds with None/Faint fluorescence are recommended as there
                        is no visual effect.
                    </li>
                    <li>
                        Diamonds with Strong/Very Strong fluorescence should be avoided
                        as they will appear milky/hazy, like having an oily effect, in
                        natural sunlight, even though they will be almost half price as
                        compared to diamonds with none fluorescence. Diamonds with
                        medium fluorescence may or may not have this effect and a
                        physical inspection by our experts can confirm this.
                    </li>
                    <li>
                        It is a common misconception based on advice by some so called
                        online diamond experts that for diamonds below J color,
                        strong/very strong fluorescence should be selected to make the
                        diamond look “whiter”. It is not true, as a milky/hazy diamond
                        can look “whiter” but you want the diamond to sparkle and shine,
                        not look “Milky/White” and such diamonds will always have
                        visiibly lesser sparkle and shine.
                    </li>
                </ul>
                <a href='#'>Learn More</a>
            </>

        case "Very Strong":
            return <>
                <p>
                    Fluorescence is often overlooked by most people as they tend to
                    focus only on the 4C’s. However, this is extremely important and
                    affects the visual apperance of the diamond as well as the price
                    greatly.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Diamonds with None/Faint fluorescence are recommended as there
                        is no visual effect.
                    </li>
                    <li>
                        Diamonds with Strong/Very Strong fluorescence should be avoided
                        as they will appear milky/hazy, like having an oily effect, in
                        natural sunlight, even though they will be almost half price as
                        compared to diamonds with none fluorescence. Diamonds with
                        medium fluorescence may or may not have this effect and a
                        physical inspection by our experts can confirm this.
                    </li>
                    <li>
                        It is a common misconception based on advice by some so called
                        online diamond experts that for diamonds below J color,
                        strong/very strong fluorescence should be selected to make the
                        diamond look “whiter”. It is not true, as a milky/hazy diamond
                        can look “whiter” but you want the diamond to sparkle and shine,
                        not look “Milky/White” and such diamonds will always have
                        visiibly lesser sparkle and shine.
                    </li>
                </ul>
                <a href='#'>Learn More</a>
            </>

        default:
            return null;
    }

}

export default ExpertFluore;