import Row from "react-bootstrap/Row";
import "../../pages/custom-design-ring.css";
import {useDispatch} from "react-redux";
import {customDesignAction} from "../../redux/reducers/customDesignRing";

export default function WhoWill() {
    const dispatch = useDispatch();
    return (
        <Row>
            <div className="mdle-content">
                <p className="static-text-design">Design your bespiock ring</p>
                <button
                    onClick={(e) => dispatch(customDesignAction.CHANGECOMPONENT("whoWill"))}
                    className="start-btn "
                >
                    Let’s create something amazing!
                </button>
            </div>
            <p className="mx-w-p">
                Design your ring from scratch. Just tell us your preferences
                and our gemologists and design consultants will work with you
                to find the perfect diamond/sapphire and create a stunning,
                bespoke ring design - just for you!
            </p>
        </Row>
    );
}
