import {createSlice} from "@reduxjs/toolkit";


type firstInitialState = {
    journey: string,
    diamonds: any,
    totalDiamonds: number,
    returnedDiamonds: number,
    filterData: any,
    columnsManager: {
        shape: boolean,
        carat: boolean,
        color: boolean,
        clearity: boolean,
        cut: boolean,
        polish: boolean,
        symmetry: boolean,
        fluorescene: boolean,
        fancy_color_intensity: any,
        certificate: boolean,
        price: boolean,
        table: boolean,
        totaldepth: boolean,
        measurements: boolean,
        LWRatio: boolean,
        ShadeMilky: boolean
    },
    comparisonDiamond: any[],
    comparisonEarring: any[],
    listingType: string,
    sorting: string,

    colorTab: string,
    access_token: string
}

const initialState: firstInitialState = {
    journey: "",
    diamonds: [],
    totalDiamonds: 0,
    returnedDiamonds: 0,
    filterData: {},
    columnsManager: {
        shape: true,
        carat: true,
        color: true,
        clearity: true,
        cut: true,
        polish: true,
        symmetry: true,
        fluorescene: true,
        fancy_color_intensity: "",
        certificate: true,
        price: true,
        table: false,
        totaldepth: false,
        measurements: false,
        LWRatio: false,
        ShadeMilky: false
    },
    comparisonDiamond: [],
    comparisonEarring: [],
    listingType: "table",
    sorting: "",

    colorTab: "color",
    access_token: ""

};

const diamondSlice = createSlice({
    name: 'diamondReducer',
    initialState: initialState,
    reducers: {
        ADD_DIAMONDS_DATA: (state, action) => {
            state.diamonds = action.payload.diamonds
            state.totalDiamonds = action.payload.totalDiamonds
            state.returnedDiamonds = action.payload.returnedDiamonds

        },
        FILTER_OPTIONS: (state, action) => {

        },
        COLUMNS_MANAGER: (state, action) => {

            if (action.payload.column.shape != undefined) state.columnsManager.shape = action.payload.column.shape
            if (action.payload.column.carat != undefined) state.columnsManager.carat = action.payload.column.carat
            if (action.payload.column.color != undefined) state.columnsManager.color = action.payload.column.color
            if (action.payload.column.clearity != undefined) state.columnsManager.clearity = action.payload.column.clearity
            if (action.payload.column.cut != undefined) state.columnsManager.cut = action.payload.column.cut
            if (action.payload.column.polish != undefined) state.columnsManager.polish = action.payload.column.polish
            if (action.payload.column.symmetry != undefined) state.columnsManager.symmetry = action.payload.column.symmetry
            if (action.payload.column.fluorescene != undefined) state.columnsManager.fluorescene = action.payload.column.fluorescene
            if (action.payload.column.certificate != undefined) state.columnsManager.certificate = action.payload.column.certificate
            if (action.payload.column.price != undefined) state.columnsManager.price = action.payload.column.price
            if (action.payload.column.table != undefined) state.columnsManager.table = action.payload.column.table
            if (action.payload.column.totaldepth != undefined) state.columnsManager.totaldepth = action.payload.column.totaldepth
            if (action.payload.column.measurements != undefined) state.columnsManager.measurements = action.payload.column.measurements
            if (action.payload.column.LWRatio != undefined) state.columnsManager.LWRatio = action.payload.column.LWRatio
            if (action.payload.column.ShadeMilky != undefined) state.columnsManager.ShadeMilky = action.payload.column.ShadeMilky

        },
        ADD_IN_COMPARISON: (state, action) => {
            state.comparisonDiamond.push(action.payload.diamond)
        },
        REMOVE_IN_COMPARISON: (state, action) => {


            state.comparisonDiamond = state.comparisonDiamond.filter(item => item.diamond_id !== action.payload)
        },
        ADD_IN_EARRING_COMPARISON: (state, action) => {
            let cd = state.comparisonEarring;
            cd.push(action.payload)

            state.comparisonEarring = cd

        },
        REMOVE_IN_EARRING_COMPARISON:
            (state, action) => {

                state.comparisonEarring = state.comparisonEarring.filter(item => item.one.diamond_id !== action.payload.one.diamond_id)


            },
        LISTING_STYLE:
            (state, action) => {

                state.listingType = action.payload

            },

        ADDJOURNEY: (state, action) => {


            state.journey = action.payload

        },
        COLORTAB: (state, action) => {

            state.colorTab = action.payload

        },
        ADD_API_TOKEN: (state, action) => {

            state.access_token = action.payload

        }
    }
})

export const diamondReducerAction = diamondSlice.actions;
export default diamondSlice.reducer;