import React, {useEffect, useState} from "react";
import {Button, Modal} from "antd";
import QuizShape from "./QuizComponents/quizShapes";
import QuizPrice from "./QuizComponents/quizPrice";
import QuizQuality from "./QuizComponents/quizQuality";
import QuizDetails from "./QuizComponents/quizDetails";
import {useSearchParams} from "react-router-dom";

const Quiz = (props: any) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [currentComponent, setCurrentComponent] = useState<number>(1);
    const [searchParams, setSearchParams] = useSearchParams();
    // store the child component state values
    const [shapeState, setShapeState] = useState<any>(null);
    const [priceState, setPriceState] = useState<any>(null);
    const [qualityState, setQualityState] = useState<any>(null);
    const [detailsState, setDetailsState] = useState<any>(null);

    const handleNext = () => {
        setCurrentComponent((currentComponent) =>
            currentComponent < 5 ? currentComponent + 1 : currentComponent
        );
    };

    const handlePrevious = () => {
        setCurrentComponent((currentComponent) =>
            currentComponent > 1 ? currentComponent - 1 : currentComponent
        );
    };

    const renderComponent = () => {
        switch (currentComponent) {
            case 1:
                return (
                    <div className="first-data">
                        <ul id="progresssteps">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <p>Let's find the perfect diamond for you!</p>
                    </div>
                );
            case 2:
                return (
                    <QuizShape onStateChange={(newState: any) => setShapeState(newState)}/>
                );
            case 3:
                return (
                    <QuizPrice onStateChange={(newState: any) => setPriceState(newState)}/>
                );
            case 4:
                return (
                    <QuizQuality
                        onStateChange={(newState: any) => setQualityState(newState)}
                    />
                );
            case 5:
                return (
                    <>
                        <QuizDetails
                            onStateChange={(newState: any) => setDetailsState(newState)}
                        />
                        <div className="text-center btn-search-div">
                            <a href="#" onClick={handleSubmit} className="search-go-btn">
                                {" "}
                                Or Go Directly To Search Results{" "}
                            </a>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    const handleShowModal = () => {
        setIsModalOpen(true);
    };

    const handleModalCancel = () => {
        setIsModalOpen(false);
        setCurrentComponent(1);
    };

    const handleSubmit = () => {
        if (shapeState !== null && priceState !== null && qualityState !== null) {
        }
        setCurrentComponent(1);
        setIsModalOpen(false);
    };
    useEffect(() => {
        const quiz = searchParams.getAll("popup");

        if (quiz[0] == "quiz") {
            setIsModalOpen(true);
        }
    }, []);
    let btn;
    if (currentComponent === 5) {
        btn = (
            <Button
                key="submit"
                type="primary"
                onClick={handleSubmit}
                className="submit-btn-quiz"
            >
                {" "}
                Submit{" "}
            </Button>
        );
    } else {
        btn = (
            <Button
                key="submit"
                type="primary"
                onClick={handleNext}
                className="btn-next"
            >
                {" "}
                NEXT{" "}
            </Button>
        );
    }

    return (
        <>
            <div className="inner-one">Need help?</div>
            |
            <div className="inner-sec">
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        handleShowModal();
                    }}
                >
                    {" "}
                    START OUR QUIZ{" "}
                </a>
            </div>
            <Modal
                open={isModalOpen}
                onCancel={handleModalCancel}
                footer={[
                    <Button
                        className="btn-prev"
                        key="back"
                        onClick={handlePrevious}
                        disabled={currentComponent === 1 ? true : false}
                        style={{display: currentComponent === 1 ? 'none' : ''}}
                    >
                        {" "}
                        PREVIOUS{" "}
                    </Button>,
                    btn,
                ]}
                className="quiz-popmodel"
            >
                {renderComponent()}
            </Modal>
        </>
    );
};

export default Quiz;
