import React from "react";

const ExpertCut = ({cut}: any) => {

    switch (cut) {
        case "Excellent":
            return <>
                <p>
                    There should be no compromises on the cut and we recommend
                    selecting only the best.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        For Round Diamonds, select “Excellent” cut only. Polish and
                        Symmetry can be Excellent or Very Good, however if you want the
                        best and if the diamond is over 0.90 carat, then select a Triple
                        Excellent diamond - Excellent cut, Excellent polish and
                        Excellent symmetry.
                    </li>
                    <li>
                        The recommended range of cutting parameters very for each shape,
                        learn more here. For Round diamonds, if you are selecting
                        Excellent cut and if the diamond is under 1.00 carat, then it is
                        not necessary to strictly stick to the recommended range of
                        cutting parameters.
                    </li>
                    <li>
                        The recommended range of cutting parameters very for each shape,
                        learn more here. For Round diamonds, if you are selecting
                        Excellent cut and if the diamond is under 1.00 carat, then it is
                        not necessary to strictly stick to the recommended range of
                        cutting parameters.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>

        case "Very Good":
            return <>
                <p>
                    There should be no compromises on the cut and we recommend
                    selecting only the best.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        For Round Diamonds, select “Excellent” cut only. Polish and
                        Symmetry can be Excellent or Very Good, however if you want the
                        best and if the diamond is over 0.90 carat, then select a Triple
                        Excellent diamond - Excellent cut, Excellent polish and
                        Excellent symmetry.
                    </li>
                    <li>
                        The recommended range of cutting parameters very for each shape,
                        learn more here. For Round diamonds, if you are selecting
                        Excellent cut and if the diamond is under 1.00 carat, then it is
                        not necessary to strictly stick to the recommended range of
                        cutting parameters.
                    </li>
                    <li>
                        The recommended range of cutting parameters very for each shape,
                        learn more here. For Round diamonds, if you are selecting
                        Excellent cut and if the diamond is under 1.00 carat, then it is
                        not necessary to strictly stick to the recommended range of
                        cutting parameters.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>

        case "Good":
            return <>
                <p>
                    There should be no compromises on the cut and we recommend
                    selecting only the best.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        For Round Diamonds, select “Excellent” cut only. Polish and
                        Symmetry can be Excellent or Very Good, however if you want the
                        best and if the diamond is over 0.90 carat, then select a Triple
                        Excellent diamond - Excellent cut, Excellent polish and
                        Excellent symmetry.
                    </li>
                    <li>
                        The recommended range of cutting parameters very for each shape,
                        learn more here. For Round diamonds, if you are selecting
                        Excellent cut and if the diamond is under 1.00 carat, then it is
                        not necessary to strictly stick to the recommended range of
                        cutting parameters.
                    </li>
                    <li>
                        The recommended range of cutting parameters very for each shape,
                        learn more here. For Round diamonds, if you are selecting
                        Excellent cut and if the diamond is under 1.00 carat, then it is
                        not necessary to strictly stick to the recommended range of
                        cutting parameters.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>

        case "Fair":
            return <>
                <p>
                    There should be no compromises on the cut and we recommend
                    selecting only the best.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        For Round Diamonds, select “Excellent” cut only. Polish and
                        Symmetry can be Excellent or Very Good, however if you want the
                        best and if the diamond is over 0.90 carat, then select a Triple
                        Excellent diamond - Excellent cut, Excellent polish and
                        Excellent symmetry.
                    </li>
                    <li>
                        The recommended range of cutting parameters very for each shape,
                        learn more here. For Round diamonds, if you are selecting
                        Excellent cut and if the diamond is under 1.00 carat, then it is
                        not necessary to strictly stick to the recommended range of
                        cutting parameters.
                    </li>
                    <li>
                        The recommended range of cutting parameters very for each shape,
                        learn more here. For Round diamonds, if you are selecting
                        Excellent cut and if the diamond is under 1.00 carat, then it is
                        not necessary to strictly stick to the recommended range of
                        cutting parameters.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>

        case "Poor":
            return <>
                <p>
                    There should be no compromises on the cut and we recommend
                    selecting only the best.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        For Round Diamonds, select “Excellent” cut only. Polish and
                        Symmetry can be Excellent or Very Good, however if you want the
                        best and if the diamond is over 0.90 carat, then select a Triple
                        Excellent diamond - Excellent cut, Excellent polish and
                        Excellent symmetry.
                    </li>
                    <li>
                        The recommended range of cutting parameters very for each shape,
                        learn more here. For Round diamonds, if you are selecting
                        Excellent cut and if the diamond is under 1.00 carat, then it is
                        not necessary to strictly stick to the recommended range of
                        cutting parameters.
                    </li>
                    <li>
                        The recommended range of cutting parameters very for each shape,
                        learn more here. For Round diamonds, if you are selecting
                        Excellent cut and if the diamond is under 1.00 carat, then it is
                        not necessary to strictly stick to the recommended range of
                        cutting parameters.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>

        default:
            return null;

    }
}

export default ExpertCut;