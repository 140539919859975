import React, {useState} from "react";
import {Modal} from 'antd';
import env from '../../env.json';


const SocialComponent = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const domain = env.wphost;

    let pageUrl = window.location.href;

    const fbLink = "https://www.facebook.com/sharer.php?u=" + pageUrl;
    const twitLink = "https://twitter.com/intent/tweet?url=" + pageUrl;
    const pintLink = "http://www.pinterest.com/pin/create/button/?url=" + pageUrl;
    const wpLink = "https://wa.me/?text=" + pageUrl;
    const mailLink = "mailto:?subject=Product Recommendation&body=" + pageUrl;

    const handleCopyClick = (e: any) => {
        e.preventDefault();
        const el = document.createElement('input');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopied(true);
    };

    return (
        <>
            <div className="social-btns">
                <a href="#" onClick={() => setIsModalOpen(true)}> Share </a></div>
            <Modal open={isModalOpen} centered onCancel={() => setIsModalOpen(false)} footer={[]}
                   className="social-popup">
                <div className="top-heading">
                    <h4>Share</h4>
                </div>
                <ul className="social-iconlist">
                    <li>
                        {" "}
                        <a href={fbLink} target="_blank" rel="noreferrer">
                            {" "}
                            <svg
                                width="44"
                                height="44"
                                viewBox="0 0 44 44"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M43.875 22.134C43.875 9.97846 34.0817 0.125 22.0027 0.125C9.91831 0.127734 0.125 9.97846 0.125 22.1367C0.125 33.1193 8.12478 42.2236 18.5797 43.875V28.4961H13.0296V22.1367H18.5852V17.2838C18.5852 11.7692 21.8524 8.72353 26.8474 8.72353C29.2425 8.72353 31.7441 9.15278 31.7441 9.15278V14.5662H28.9855C26.2706 14.5662 25.423 16.264 25.423 18.0056V22.134H31.4871L30.5192 28.4933H25.4203V43.8723C35.8752 42.2209 43.875 33.1166 43.875 22.134Z"
                                    fill="#325094"
                                />
                            </svg>
                            <span className="icon-names"> Facebook </span>{" "}
                        </a>{" "}
                    </li>
                    <li>
                        {" "}
                        <a href={twitLink} target="_blank" rel="noreferrer">
                            {" "}
                            <svg width="44" height="44" viewBox="0 0 44 44" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M43.875 22.134C43.875 9.97846 34.0817 0.125 22.0027 0.125C9.91831 0.127734 0.125 9.97846 0.125 22.1367C0.125 33.1193 8.12478 42.2236 18.5797 43.875V28.4961H13.0296V22.1367H18.5852V17.2838C18.5852 11.7692 21.8524 8.72353 26.8474 8.72353C29.2425 8.72353 31.7441 9.15278 31.7441 9.15278V14.5662H28.9855C26.2706 14.5662 25.423 16.264 25.423 18.0056V22.134H31.4871L30.5192 28.4933H25.4203V43.8723C35.8752 42.2209 43.875 33.1166 43.875 22.134Z"
                                    fill="#325094"/>
                            </svg>
                            <span className="icon-names twitter"> Twitter </span>{" "}
                        </a>
                    </li>
                    <li>
                        {" "}
                        <a href={pintLink} target="_blank" rel="noreferrer">
                            {" "}
                            <svg
                                width="44"
                                height="44"
                                viewBox="0 0 44 44"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M22 0.125C9.91862 0.125 0.125 9.91862 0.125 22C0.125 34.0814 9.91862 43.875 22 43.875C34.0814 43.875 43.875 34.0814 43.875 22C43.875 9.91862 34.0814 0.125 22 0.125ZM23.4971 27.1839C22.0934 27.0768 21.5055 26.3818 20.4072 25.7165C19.8034 28.8861 19.0651 31.9258 16.8776 33.514C16.2008 28.7197 17.8665 25.124 18.6413 21.3027C17.3219 19.0811 18.7985 14.6126 21.583 15.7109C25.0078 17.069 18.6162 23.971 22.9069 24.8346C27.3867 25.7324 29.2165 17.0599 26.4388 14.2435C22.4238 10.1715 14.7562 14.1501 15.6995 19.9788C15.9274 21.403 17.4017 21.8359 16.2874 23.8024C13.7194 23.2327 12.9515 21.207 13.0518 18.5068C13.209 14.0863 17.0234 10.9896 20.847 10.5635C25.6846 10.0257 30.2236 12.3385 30.8525 16.8913C31.5566 22.0273 28.6673 27.585 23.4971 27.1839Z"
                                    fill="#CE0A30"
                                />
                            </svg>
                            <span className="icon-names"> Pinterest </span>{" "}
                        </a>
                    </li>
                    <li>
                        {" "}
                        <a href={mailLink} target="_blank" rel="noreferrer">
                            {" "}
                            <svg
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="25" cy="25" r="21.875" fill="#D33063"/>
                                <path
                                    d="M39.0625 21.3007V33.2933C39.0625 34.409 38.5995 35.479 37.7754 36.2679C36.9512 37.0568 35.8335 37.5 34.668 37.5H15.332C14.1665 37.5 13.0488 37.0568 12.2246 36.2679C11.4005 35.479 10.9375 34.409 10.9375 33.2933V21.3007L24.5535 28.9704C24.6888 29.0468 24.843 29.0871 25 29.0871C25.157 29.0871 25.3112 29.0468 25.4465 28.9704L39.0625 21.3007ZM34.668 15.625C35.7493 15.6248 36.7927 16.0063 37.5986 16.6964C38.4045 17.3866 38.9163 18.3369 39.0361 19.3656L25 27.2692L10.9639 19.3656C11.0837 18.3369 11.5955 17.3866 12.4014 16.6964C13.2073 16.0063 14.2507 15.6248 15.332 15.625H34.668Z"
                                    fill="white"
                                />
                            </svg>
                            <span className="icon-names"> Mail </span>{" "}
                        </a>
                    </li>
                    <li>
                        {" "}
                        <a href={wpLink} target="_blank" rel="noreferrer" data-action="share/whatsapp/share">
                            {" "}
                            <svg
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="25" cy="25" r="21.875" fill="#64B161"/>
                                <path
                                    d="M31.3246 27.7587C30.9825 27.583 29.278 26.748 28.961 26.635C28.644 26.5157 28.4117 26.4592 28.1826 26.8108C27.9503 27.1592 27.2911 27.9345 27.0839 28.1699C26.883 28.4022 26.679 28.4305 26.3369 28.2578C24.3029 27.2408 22.9688 26.4435 21.6285 24.1427C21.2738 23.5306 21.9832 23.5746 22.6455 22.2531C22.7585 22.0208 22.702 21.8231 22.6141 21.6473C22.5262 21.4715 21.8357 19.7702 21.5469 19.0765C21.2675 18.4016 20.9788 18.4958 20.7684 18.4833C20.5676 18.4707 20.3384 18.4707 20.1061 18.4707C19.8739 18.4707 19.5003 18.5586 19.1833 18.9007C18.8663 19.2492 17.9717 20.0872 17.9717 21.7885C17.9717 23.4898 19.2115 25.1377 19.3811 25.37C19.5568 25.6023 21.82 29.0928 25.2948 30.5963C27.492 31.5443 28.3521 31.6259 29.4507 31.4627C30.1193 31.3622 31.4972 30.6277 31.7829 29.8147C32.0685 29.0049 32.0685 28.3112 31.9838 28.1668C31.899 28.013 31.6667 27.9251 31.3246 27.7587Z"
                                    fill="white"
                                />
                                <path
                                    d="M37.97 19.5507C37.2606 17.8651 36.2436 16.3521 34.9472 15.0526C33.6508 13.7562 32.1379 12.7361 30.4492 12.0298C28.7228 11.3048 26.8896 10.9375 25 10.9375H24.9372C23.035 10.9469 21.1925 11.3236 19.4598 12.0644C17.7868 12.78 16.2864 13.797 15.0026 15.0934C13.7188 16.3898 12.7112 17.8965 12.0143 19.5758C11.2924 21.3147 10.9283 23.1636 10.9377 25.0657C10.9471 27.2441 11.4682 29.4069 12.4444 31.3404V36.1116C12.4444 36.9088 13.091 37.5555 13.8883 37.5555H18.6625C20.5961 38.5317 22.7588 39.0527 24.9372 39.0621H25.0031C26.8833 39.0621 28.7071 38.698 30.424 37.9855C32.1034 37.2855 33.6132 36.2811 34.9064 34.9972C36.2028 33.7134 37.2229 32.213 37.9355 30.54C38.6762 28.8073 39.0529 26.9648 39.0623 25.0626C39.0717 23.151 38.7014 21.2959 37.97 19.5507ZM33.2271 33.2991C31.0267 35.4775 28.1075 36.6766 25 36.6766H24.9466C23.0539 36.6672 21.1737 36.1963 19.5132 35.3111L19.2495 35.1699H14.8299V30.7503L14.6887 30.4866C13.8035 28.8262 13.3327 26.9459 13.3233 25.0532C13.3107 21.9237 14.5066 18.9857 16.7007 16.7727C18.8917 14.5598 21.8203 13.3356 24.9498 13.3231H25.0031C26.5726 13.3231 28.095 13.6275 29.5295 14.2302C30.9294 14.8172 32.185 15.6616 33.2648 16.7413C34.3414 17.818 35.1889 19.0767 35.7759 20.4766C36.3848 21.9268 36.6893 23.4649 36.683 25.0532C36.6642 28.1795 35.4369 31.1081 33.2271 33.2991Z"
                                    fill="white"
                                />
                            </svg>
                            <span className="icon-names"> WhatsApp </span>{" "}
                        </a>
                    </li>
                    <li>
                        {" "}
                        <a href="#" onClick={handleCopyClick}>
                            {" "}
                            <svg
                                width="50"
                                height="50"
                                viewBox="0 0 50 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="25" cy="25" r="21.875" fill="#EAEAEA"/>
                                <path
                                    d="M24.5156 17.942C29.7117 14.942 33.9324 16.2524 35.8254 19.5313C37.7185 22.8101 36.743 27.1205 31.5468 30.1205M21.103 28.0313L28.8972 23.5313M18.4534 21.442C13.2573 24.442 12.2818 28.7524 14.1748 32.0313C16.0678 35.3101 20.2885 36.6205 25.4847 33.6205"
                                    stroke="#333333"
                                    stroke-width="2"
                                    strokeLinecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <span className="icon-names"> {!copied ? 'Copylink' : 'Copied!'} </span>{" "}
                        </a>
                    </li>
                </ul>
            </Modal>
        </>
    );
};

export default SocialComponent;
