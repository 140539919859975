import {createSlice, PayloadAction} from "@reduxjs/toolkit";


type wishListInitialStateDto = {
    diamonds: any[],
    earringDiamonds: any[]
}

const wishListInitialState: wishListInitialStateDto = {
    diamonds: [],
    earringDiamonds: []
}
export const wishlistSlice = createSlice({
    name: 'wishListInitialState',
    initialState: wishListInitialState,
    reducers: {
        loadWishList: (state: wishListInitialStateDto, action: PayloadAction<any>) => {

            state.diamonds = action.payload

        },
        ADDWISHLIST: (state: wishListInitialStateDto, action) => {
            const d = state.diamonds;
            d.push(action.payload)

            state.diamonds = d

        },
        REMOVEWISHLIST: (state: wishListInitialStateDto, action) => {

            state.diamonds = state.diamonds.filter(item => item !== action.payload)

        },
        LOADEARRINGWISHLIST: (state: wishListInitialStateDto, action) => {

            state.earringDiamonds = action.payload

        },
        ADDWISHLISTEARRING: (state: wishListInitialStateDto, action) => {
            const de = state.earringDiamonds;
            de.push(action.payload)

            state.earringDiamonds = de

        },
        REMOVEWISHLISTEARRING: (state: wishListInitialStateDto, action) => {

            state.earringDiamonds = state.earringDiamonds.filter((item: any) => item.one !== action.payload.one)


        }
    }
})

export const wishlistSliceAction = wishlistSlice.actions;
export default wishlistSlice.reducer;