import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {customDesignAction} from "../../redux/reducers/customDesignRing";
import {RootState} from "../../redux/store";

export default function WhoWill() {
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const metal = [{name: "White Gold"}, {name: "Rose Gold"}, {name: "Yellow Gold"}, {name: "Platinum"}];
    const metalPurity = [{name: "18K"}, {name: "14K"}, {name: "Platium 950"}];
    const metalHTML = metal.map((i, index) => (<span key={index}>
        <input
            type="radio"
            name="carat"
            value={i.name}
            checked={state.customDesign.metal == i.name}
            id={`carat-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({metal: i.name}))
                } else {
                    dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({metal: i.name}))
                }
            }}
        />
        <label
            htmlFor={`carat-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract color-shappire"
        >
            <i className="circle-color color-gold"></i>{i.name}
        </label>
    </span>));
    const metalPurityHTML = metalPurity.map((i, index) => (<span key={index}>
        <input
            type="radio"
            name="colorRange"
            value={i.name}
            checked={state.customDesign.metalColor == i.name}
            id={`colorRange-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({metalColor: i.name}))
                } else {
                    dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({metalColor: i.name}))
                }
            }}
        />
        <label
            htmlFor={`colorRange-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract"
        >
            {i.name}
        </label>
    </span>));

    return (
        <>
            <Row>
                <Col>
                    <div className="text-center">
                        <h1>Choose Metal</h1>
                    </div>
                </Col>

            </Row>
            <Row><Col md={12}>
                <div className="inner-label">Metal</div>
            </Col>
                <Col md={12}>
                    <div className="shape-selct-diamond text-center">
                        {metalHTML}
                    </div>
                </Col>
            </Row>
            <Row> <Col md={12}>
                <div className="inner-label">Metal Purity</div>
            </Col>
                <Col md={12}>
                    <div className="shape-selct-diamond text-center">
                        {metalPurityHTML}
                    </div>
                </Col>
            </Row>
            <Row>
                <span>
                    <input
                        type="radio"
                        name="shape"
                        checked={state.customDesign.metalColor == "notSure"}
                        id={`notSure`}
                        className="input-hidden"
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({metal: "notSure"}))
                                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({metalColor: "notSure"}))
                            } else {
                                dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({metal: "notSure"}))
                                dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({metalColor: "notSure"}))
                            }
                        }}
                    />
                    <label htmlFor={`notSure`} className="diamond-mr-20">

                        <span>Not Sure</span>
                    </label>
                </span>
            </Row>
        </>
    );
}
