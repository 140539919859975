import React, {useState} from "react";
import {useSelector} from "react-redux";

import {Slider} from 'antd';
import {RootState} from "../../redux/store";

type QuizPriceDto = {
    onStateChange: Function
}

const QuizPrice = ({onStateChange}: QuizPriceDto) => {
    const state = useSelector((state: RootState) => state);

    function functionGetValue(APIMin: number, APIMax: number, sliderValue: number) {
        return (sliderValue / 100) * (APIMax - APIMin) + APIMin;
    }

    const [price, setPrice] = useState<any>({});


    return (
        <div className="spend-sec">
            <ul id="progresssteps">
                <li className="active"></li>
                <li className="active"></li>
                <li></li>
                <li></li>
            </ul>
            <div className="diamond-price-filter-shop m-top-60">
                <h4 className="quiz-heading">How much do you want to spend?</h4>
                <div className="slide-spend">
                    <Slider
                        range={true}
                        step={10}
                        tooltipVisible={false}
                        onChange={(value) => {
                            setPrice({
                                min: functionGetValue(parseInt(state.endUserFilter.filterMainData.price.min), parseInt(state.endUserFilter.filterMainData.price.max), value[0]),
                                max: functionGetValue(parseInt(state.endUserFilter.filterMainData.price.min), parseInt(state.endUserFilter.filterMainData.price.max), value[1]),
                            });
                            onStateChange({
                                min: functionGetValue(parseInt(state.endUserFilter.filterMainData.price.min), parseInt(state.endUserFilter.filterMainData.price.max), value[0]),
                                max: functionGetValue(parseInt(state.endUserFilter.filterMainData.price.min), parseInt(state.endUserFilter.filterMainData.price.max), value[1]),
                            });

                        }}
                    /></div>
                <div className="dis-input-price">
                    <div className="price-input">
                        <span className="currency-symbol">Rs</span>
                        <input
                            type="number"
                            name="min-price"
                            id="min-price"
                            className="input-hidden width-fixes"
                            disabled={true}
                            placeholder="00"
                            value={(price.min)}
                            readOnly
                        />
                    </div>
                    <div className="price-input">
                        <span className="currency-symbol">Rs</span>
                        <input
                            type="number"
                            name="max-price"
                            id="max-price"
                            className="input-hidden width-fixes"
                            disabled={true}
                            placeholder="00"
                            value={(price.max)}
                            readOnly
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuizPrice;