import React, {useState} from "react";

const QuizDetails = ({onStateChange}: any) => {

    const [details, setDetails] = useState({});

    const handleUpdate = (e: any) => {
        setDetails({...details, [e.target.id]: e.target.value});
        onStateChange({...details, [e.target.id]: e.target.value});
        // console.log(details);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
    }

    return (
        <div className="diamond-details-filter-shop">
            <ul id="progresssteps">
                <li className="active"></li>
                <li className="active"></li>
                <li className="active"></li>
                <li className="active"></li>
            </ul>
            <div className="main-details-fill">
                <h4 className="quiz-heading">Your Details</h4>
                <p className="quiz-sub-heading">Our Diamond Experts will reach out with the best diamonds</p>
                <form className="customer-form" onSubmit={handleSubmit}>
                    <p>
                        <input type="text" placeholder="Your Name" onChange={handleUpdate} name="name" id="name"/>
                    </p>
                    <p>
                        <input type="email" placeholder="Email id" onChange={handleUpdate} name="email" id="email"/>
                    </p>
                    <p>
                        <input type="tel" placeholder="+91 | Enter your mobile no*" onChange={handleUpdate} name="phone"
                               id="phone"/>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default QuizDetails;