import React, {useState} from 'react';
import Row from "react-bootstrap/Row";
import {useDispatch, useSelector} from "react-redux";
import {customDesignAction} from "../../redux/reducers/customDesignRing";
import {PlusOutlined} from "@ant-design/icons";
import {Modal, Upload} from "antd";
import {RootState} from "../../redux/store";

const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
export default function WhoWill() {
    const state = useSelector((state: RootState) => state);

    const dispatch = useDispatch();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({fileList}: any) => {
        dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({designFiles: fileList}));
    }
    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    return (
        <>
            <Row>
                Design Inspiration
            </Row>
            <Row>
                <input
                    value={state.customDesign.designInspiration}
                    onChange={e => {
                        dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({designInspiration: e.target.value}))
                    }}
                    type="textarea"
                />
            </Row>
            <Row>
                <Upload
                    // action=""
                    customRequest={async ({file, onSuccess}) => {
                        if (onSuccess) {
                            onSuccess("ok");
                        }

                    }}
                    listType="picture-card"
                    fileList={state.customDesign.designFiles}
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {state.customDesign.designFiles.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
            </Row>
        </>
    );
}
