import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Image} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {customDesignAction} from "../../redux/reducers/customDesignRing";
import {RootState} from "../../redux/store";

export default function WhoWill() {
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const styles = [{
        name: "Solitarie"
    }, {
        name: "Vintage"
    }, {
        name: "Three Stone"
    }, {
        name: "Halo"
    }, {
        name: "Channel Set"
    }, {
        name: "Pave"
    }, {
        name: "Side Stone"
    },];
    const styleHTML = styles.map(i => (<span>
        <input
            type="radio"
            name="shape"
            id={`shape-${i.name}`}
            checked={state.customDesign.style == i.name}
            className="input-hidden"
            onChange={(e) => {
                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({style: i.name}))
            }}
        />
        <label htmlFor={`shape-${i.name}`} className="diamond-mr-20 border-square">
            <Image height="28px" width="68px"
                   src="https://sarvada.wpstagingserver.com/wp-content/uploads/2024/02/Solitarie.png" alt="Diamond"/>
            <span>{i.name}</span>
        </label>
    </span>))
    return (
        <>
            <Row>
                <div className="text-center">
                    <h1>Choose a style</h1>
                </div>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="shape-selct-diamond text-center" id="choose-style-shape">{styleHTML}</div>
                </Col>

            </Row>
            <Row>
                <div className="text-center">
                    <input
                        type="checkbox"
                        name="shape"
                        checked={state.customDesign.style == "notSure"}
                        id={`notSure`}
                        className="input-hidden"
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(customDesignAction.ADDCUSTOMDESIGNRINGVALUES({style: "notSure"}))
                            } else {
                                dispatch(customDesignAction.REMOVECUSTOMDESIGNRINGVALUES({style: "notSure"}))
                            }
                        }}
                    />
                    <label htmlFor={`notSure`} className="diamond-mr-20">

                        <span>Not Sure</span>
                    </label>
                </div>
            </Row>
        </>
    );
}
