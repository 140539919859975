import React from "react";
import Table from "react-bootstrap/Table";
import {Row} from "react-bootstrap";
import {Pagination} from 'antd';
import {useNavigate, useParams} from "react-router-dom";
import env from '../../../env.json'
import {useDispatch, useSelector} from "react-redux";
import IndFunctions from "../../common/IndFunctions";
import {diamondReducerAction} from "../../../redux/reducers/diamondReducer";
import {wishlistSliceAction} from "../../../redux/reducers/wishlist";
import GridEarringListing from '../grid/earringGridListing'
import {RootState} from "../../../redux/store";
import {endUserFilterAction} from "../../../redux/reducers/endUserFilter";

type TablePropsDto = {
    tableStyle: string
}
export default function Tables({tableStyle}: TablePropsDto) {
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const {productID} = useParams();
    const navigate = useNavigate();


    function wishlistAdd(firstDiamondID: string, secondDiamondID: string) {
        IndFunctions.wishlistAPI(env.addEarringWishlist, {diamond_ids: [firstDiamondID, secondDiamondID]})
        dispatch(wishlistSliceAction.ADDWISHLISTEARRING(({one: firstDiamondID, two: secondDiamondID})))
    }

    function wishlistRemove(firstDiamondID: string, secondDiamondID: string) {
        IndFunctions.wishlistAPI(env.removeEarringWishlist, {diamond_ids: [firstDiamondID, secondDiamondID]})
        dispatch(wishlistSliceAction.REMOVEWISHLISTEARRING(({one: firstDiamondID, two: secondDiamondID})))
    }


    const diamondsData = state.diamondReducer.diamonds ? state.diamondReducer.diamonds.map((diamond: any, index: number) => (
        <tr key={index}>

            <td>
                <input
                    type="checkbox"
                    name="compare"
                    checked={state.diamondReducer.comparisonEarring.filter(item => item.one.diamond_id === diamond.one.diamond_id).length > 0 ? true : false}
                    onClick={(e: any) => e.target.checked === true ? dispatch(diamondReducerAction.ADD_IN_EARRING_COMPARISON((diamond))) : dispatch(diamondReducerAction.REMOVE_IN_EARRING_COMPARISON((diamond)))}
                    disabled={state.diamondReducer.comparisonEarring.length > 4 && state.diamondReducer.comparisonEarring.filter(item => item.one.diamond_id === diamond.one.diamond_id).length === 0}
                />
            </td>
            <td>
                <input
                    type="checkbox"
                    name="Wishlist"
                    checked={state.wishlist.earringDiamonds.filter(item => item.one === diamond.one.diamond_id).length > 0 ? true : false}
                    onClick={(e: any) => e.target.checked === true ? wishlistAdd(diamond.one.diamond_id, diamond.two.diamond_id) : wishlistRemove(diamond.one.diamond_id, diamond.two.diamond_id)}
                    disabled={state.wishlist.earringDiamonds.length > 4 && state.wishlist.earringDiamonds.filter(item => item.one === diamond.one.diamond_id).length === 0}
                />
            </td>
            {state.diamondReducer.columnsManager.shape ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}> {diamond.one.shape}</td> : false}
            {state.diamondReducer.columnsManager.carat ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}><p>{diamond.one.size}</p><p> {diamond.two ? diamond.two.size : false}</p></td> : false}
            {state.diamondReducer.columnsManager.color ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}><p>{diamond.one.color}</p><p> {diamond.two ? diamond.two.color : false}</p></td> : false}
            {state.diamondReducer.columnsManager.clearity ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}><p>{diamond.one.clarity}</p><p> {diamond.two ? diamond.two.clarity : false}</p></td> : false}
            {state.diamondReducer.columnsManager.fancy_color_intensity ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}><p>{diamond.one.clarity}</p><p> {diamond.two ? diamond.two.fancy_color_intensity : false}</p>
            </td> : false}
            {state.diamondReducer.columnsManager.cut ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}><p>{diamond.one.cut}</p><p> {diamond.two ? diamond.two.cut : false}</p></td> : false}
            {state.diamondReducer.columnsManager.polish ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}><p>{diamond.one.polish}</p><p> {diamond.two ? diamond.two.polish : false}</p></td> : false}
            {state.diamondReducer.columnsManager.symmetry ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}><p>{diamond.one.symmetry}</p><p> {diamond.two ? diamond.two.symmetry : false}</p></td> : false}
            {state.diamondReducer.columnsManager.fluorescene ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}><p>{diamond.one.fluor_intensity}</p><p> {diamond.two ? diamond.two.fluor_intensity : false}</p>
            </td> : false}
            {state.diamondReducer.columnsManager.certificate ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}><p>{diamond.one.lab}</p><p> {diamond.two ? diamond.two.lab : false}</p></td> : false}
            {state.diamondReducer.columnsManager.price ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}><p>{diamond.one.total_sales_price_in_currency + diamond.two.total_sales_price_in_currency}</p>
                <p>
                    <del>{((diamond.one.total_sales_price_in_currency + diamond.two.total_sales_price_in_currency) + (diamond.one.total_sales_price_in_currency + diamond.two.total_sales_price_in_currency) * env.priceMultiplier)}</del>
                </p>
            </td> : false}
            {state.diamondReducer.columnsManager.table ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}> {diamond.table_percent} </td> : false}
            {state.diamondReducer.columnsManager.totaldepth ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}> {diamond.depth_percent} </td> : false}
            {state.diamondReducer.columnsManager.measurements ? <td onClick={() => {
                navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
            }}> {diamond.meas_length}x{diamond.meas_width}x{diamond.meas_depth} </td> : false}
            {/* {state.diamondReducer.columnsManager.LWRatio ? <td onClick={() => {
        navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
      }}> </td> : false}
      {state.diamondReducer.columnsManager.ShadeMilky ? <td onClick={() => {
        navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
      }}> </td> : false} */}

        </tr>
    )) : <p>No Diamond Data Found</p>;
    let listingHTML;
    // console.log("this is tablesytel", tableStyle);
    if (tableStyle === "table") {
        listingHTML = (
            <>
                {" "}
                <Row className="ListView">
                    <Table
                        responsive
                        striped
                        bordered
                        hover
                        className="ext-table text-center"
                    >
                        <thead>
                        <tr>
                            <th>
                                Add To
                                <br/> Compare
                            </th>
                            <th> Wishlist</th>
                            {state.diamondReducer.columnsManager.shape ? <th> Shape </th> : false}
                            {state.diamondReducer.columnsManager.carat ? <th> Carat </th> : false}
                            {state.diamondReducer.columnsManager.color ? <th> Color </th> : false}
                            {state.diamondReducer.columnsManager.clearity ? <th> Clarity </th> : false}
                            {state.diamondReducer.columnsManager.fancy_color_intensity ? <th> Intensity </th> : false}
                            {state.diamondReducer.columnsManager.cut ? <th> Cut </th> : false}
                            {state.diamondReducer.columnsManager.polish ? <th> Polish </th> : false}
                            {state.diamondReducer.columnsManager.symmetry ? <th> Symmetry </th> : false}
                            {state.diamondReducer.columnsManager.fluorescene ? <th> Fluorescence </th> : false}
                            {state.diamondReducer.columnsManager.certificate ? <th> Certificate </th> : false}
                            {state.diamondReducer.columnsManager.price ? <th> Price </th> : false}
                            {state.diamondReducer.columnsManager.table ? <th> Table % </th> : false}
                            {state.diamondReducer.columnsManager.totaldepth ? <th> Total Depth % </th> : false}
                            {state.diamondReducer.columnsManager.measurements ? <th> Measurements </th> : false}
                            {/* {state.diamondReducer.columnsManager.LWRatio ? <th> L/W Ratio  </th> : false}
                {state.diamondReducer.columnsManager.ShadeMilky ? <th>  Shade/Milky </th> : false} */}
                        </tr>
                        </thead>
                        {state.diamondReducer.diamonds && <tbody>{diamondsData}</tbody>}
                    </Table>
                </Row>

                <Pagination
                    showSizeChanger={false}
                    total={state.diamondReducer.totalDiamonds}
                    pageSize={state.endUserFilter.page_size}
                    onChange={page => {
                        dispatch(endUserFilterAction.ADDFILTERS({page_number: page}))
                    }}
                />
            </>
        );
    } else {
        listingHTML = (
            <>
                <GridEarringListing/>
                {/* pagination */}
                <Pagination
                    showSizeChanger={false}
                    total={state.diamondReducer.totalDiamonds}
                    pageSize={state.endUserFilter.page_size}
                    onChange={page => {
                        dispatch(endUserFilterAction.ADDFILTERS({page_number: page}))
                    }}
                />
            </>
        );
    }
    return listingHTML;
}